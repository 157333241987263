@media only screen and (max-width: 768px) {
    .calendar-events {
        .event-title {
            padding: 2px 6px;
            margin: 5px 2px 4px;
            border-radius: 2px;

            & p {
                font-size: 10px;
                overflow: hidden;


            }


        }
    }
}

@media only screen and (max-width: 600px) {
    .calendar-events {

        .cal-past,
        .cal-today,
        .cal-future {
            color: transparent !important;
            font-size: 0px !important;

            &::first-letter {
                width: 100%;
                color: rgb(37, 37, 37);
                font-size: 19px;
                font-weight: 500;
            }
        }

        .event-title {
            padding: 1px 3px;
            margin: 6px 2px 4px;
            border-radius: 2px;
            border: none !important;

            & p {
                font-size: 9px;
                white-space: nowrap;
                overflow: hidden;
            }

            & small {
                display: none;
            }

        }

        .view-all {
            font-size: 10px !important;
            line-height: 12px !important;
        }

        .cal-cell-row.cal-header {
            position: sticky;
            top: 0;
            z-index: 10000;
            padding: 0px 0;
            border-bottom: 1px solid lightgray;
            padding-right: 10px;
            background-color: rgba(243, 243, 243, 0.829);
            width: calc(100% + 10px);
        }

        .cal-week-view {
            .cal-day-headers {
                .cal-header {
                    padding: 11px 0;

                    span {
                        display: none;
                    }
                }
            }
        }
    }




}


@media only screen and (max-width: 400px) {
    .calendar-events {

        .event-title {
            padding: 1px 0px;
            margin: 6px 2px 4px;
            border-radius: 2px;
            border: none !important;

            & p {
                font-size: 8px;
                white-space: nowrap;
                overflow: hidden;
            }

            & small {
                display: none;
            }

        }
    }
}