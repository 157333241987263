.onboarding-fix {
    z-index: 2000 !important;
}

.modal-web-app-ready.modal-dialog {
    top: calc(50% - 360px) !important;
    max-width: 1000px;
    .modal-content {
        background-color: #eee;
        padding: 40px;
        width: 100%;
    }
}


app-lets-begin {
    .lets-begin {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .lets-begin-lf {
        display: flex;
        width: 42%;
        background: #EEEEEF;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h5 {
            font-size: 18px;
            line-height: 26px;
            font-weight: bold;
        }

        .lets-begin-lf-box {
            max-width: 375px;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        .trainers-box {
            display: flex;
            width: 100%;
            justify-content: center;
            margin: 16px 0 32px;

            img {
                margin: 0 4px;
            }
        }

    }

    .lets-begin-rh {
        display: flex;
        width: 58%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-size: 56px;
            line-height: 64px;
            color: #232323;
            font-weight: 500;

            b {
                font-weight: 900;
            }
        }

        a {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            outline: none;
            padding: 17px 136px;
            background-color: #0FB87E;
            outline: none;
            box-shadow: none;

            &:focus {
                box-shadow: none;
            }

            &:hover {
                background: #0E8D61;
            }
        }

        .lets-begin-rh-box {
            display: flex;
            max-width: 550px;
            flex-direction: column;
            align-items: flex-start;
        }

    }



    @media screen and (max-width: 1201px) {


        .lets-begin-rh-box {
            padding-left: 40px;
        }
    }



    @media screen and (max-width: 991px) {
        .lets-begin {
            flex-direction: column;
            overflow-y: auto;
        }

        .lets-begin-rh-box {
            padding-left: 0px;
        }

        .lets-begin-lf {
            width: 100%;
            padding: 64px 40px;
            order: 2;

            .lets-begin-lf-box {
                max-width: 375px;
                display: flex;
                flex-direction: column;
                text-align: center;
            }

            .trainers-box {
                display: flex;
                width: 100%;
                justify-content: center;
                margin: 16px 0 32px;

                img {
                    margin: 0 4px;
                }
            }

        }

        .lets-begin-rh {
            width: 100%;
            padding: 80px 40px;
            order: 1;

            h1 {
                font-size: 48px;
                line-height: 56px;
                color: #232323;
                font-weight: 500;

                b {
                    font-weight: 900;
                }
            }


            a {
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                height: 56px;
                padding: 0;
                outline: none;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-width: 300px;
                background-color: #0FB87E;
                outline: none;
                box-shadow: none;

            }

            .lets-begin-rh-box {
                display: flex;
                max-width: 550px;
                flex-direction: column;
                text-align: center;
                align-items: center;
            }

        }
    }

    @media screen and (max-width: 667px) {


        .lets-begin-rh {

            h1 {
                font-size: 40px;
                line-height: 48px;
                color: #232323;
                font-weight: 500;

            }


        }
    }

    @media screen and (max-width: 567px) {

        .lets-begin-rh,
        .lets-begin-lf {
            padding: 80px 10px;

        }
    }

}

app-primary-color {
    .container {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .theme-color-content {
        .colors-box {
            flex-grow: 1;
            min-width: 278px;
            max-width: 280px;
        }
    }

    @media screen and (max-width: 991px) {
        .theme-color-content {
            .colors-box {
                max-width: 100%;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .theme-color-content {
            .colors-box {
                max-width: 100%;
            }
        }
    }


    .wizard-box-stepper-box {
        position: sticky;
        top: 0;
        padding-bottom: 32px;
        background-color: #F9F9F9;
        z-index: 100;
    }

}

app-theme-color {
    @media screen and (max-width: 768px) {


        .theme-color-content {

            .theme-color-box {
                height: 250px;
                max-width: 350px;
            }
        }
    }



    @media screen and (max-width: 565px) {

        .container {
            padding: 0;
        }

        .wizard-box-content {
            margin-top: 32px;
            overflow-y: auto;

            h2 {
                font-size: 24px;
                line-height: 32px;
            }
        }


        .theme-color-content {
            flex-direction: column;
            align-items: center;
            margin-top: 32px;

            .theme-color-box {
                margin: 0 0 30px 0 !important;
                height: 200px !important;
                width: 90%;
                max-width: 300px;

                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }
    }

}


app-upload-images {
    .images-and-preview-box {
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .wizard-box-content {
        margin-top: 52px;
    }

    .images-and-preview-box {
        padding-bottom: 26px;
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .logo-wrap {
        margin: 0 auto 30px auto;
    }


    .checkmark-selected {
        width: 30px;
        height: 30px;
        background: #A8A8A8B3;
        border-radius: 50%;
        position: relative;

        &:after {
            position: absolute;
            content: "";
            width: 18px;
            height: 12px;
            background: url("../../assets/icons/check-box-2x.png") no-repeat center center;
            top: 9px;
            left: 6px;
            background-size: cover;
        }

        &.all-five {
            background: #0FB87E;
        }
    }


    @media screen and (max-width: 1201px) {
        .container {
            overflow-y: auto;
            overflow-x: hidden;
        }

        .images-and-preview-box {
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .logo-wrap {
            margin-bottom: 30px;
        }

    }


    @media screen and (max-width: 991px) {

        .images-and-preview-box {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .wizard-box .bottom-wizard-box .images-uploaded-box {
            top: -66px;
        }
    }

    @media screen and (max-width: 768px) {

        .images-and-preview-box {
            grid-template-columns: 1fr 1fr;
        }

    }

    @media screen and (max-width: 567px) {

        .images-and-preview-box {
            grid-template-columns: 1fr;
        }

    }

}


app-upload-logo {
    .theme-color-content.flex-wrap {
        margin-top: 30px !important;
    }
}


app-uploading-assets {

    .creating-app-box {

        h1 {
            font-size: 48px !important;
            line-height: 55px !important;
        }

        p {
            text-align: center;
        }
    }

    @media screen and (max-width: 576px) {
        .creating-app-box {
            padding-top: 60px !important;

            .logo {
                width: 90px !important;
            }

            h1 {
                font-size: 30px !important;
                line-height: 35px !important;
            }

        }

        .wizard-box-stepper-box {
            margin: 30px 0px 30px !important;
        }
    }

}


app-ready {
    .app-ready {
        display: flex;
        width: 100%;
        height: 100%;

        &.is-modal {
            padding: 70px 100px 60px;

            @media screen and (max-width: 768px) {
                padding: 40px 20px 40px;
            }

            @media screen and (max-width: 576px) {
                padding: 40px 10px 40px;
            }

            .app-ready-lf {
                background: none;
                width: 30%;

                @media screen and (max-width: 768px) {
                    width: 40%;
                }

                @media screen and (max-width: 667px) {
                    width: 100%;
                }

            }

            .app-ready-rh {
                width: 70%;
                padding-left: 140px;

                @media screen and (max-width: 768px) {
                    padding-left: 30px;
                }

                @media screen and (max-width: 667px) {
                    width: 100%;
                }


                h1 {
                    font-size: 40px;
                    line-height: 48px;
                    margin: 0 0 32px;
                    padding: 0 10px;
                }

                .app-ready-rh-box {
                    p {
                        padding: 0;
                    }

                    .app-ready-rh-inner-box {
                        background: #A8A8A833;
                        border-radius: 10px;
                        padding: 32px 40px;

                        a {
                            border-color: #0FB87E;
                            background: #0FB87E;
                            color: #fff;

                            &:after {
                                background: url("../../assets/icons/ic_open_new_tab_white.svg") no-repeat center center;
                            }
                        }
                    }
                }
            }
        }
    }

    .app-ready-lf {
        display: flex;
        width: 42%;
        background: #EEEEEF;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 100px;
            height: 100px;
            border-radius: 16px;

        }

        h5 {
            font-size: 18px;
            line-height: 26px;
            font-weight: bold;
            margin: 12px 0 48px;
        }

        .app-ready-lf-box {
            max-width: 375px;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
        }

        .mockup-phone-box {
            width: 230px;
            height: 470px;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 40px;
            }

            svg-icon {
                position: absolute;
                top: 0;
                top: 11px;
                left: 12px;
                width: 207px;
                height: 449px;

                &.dark {
                    stop {
                        stop-color: #232323;
                    }

                    .primary-heading-fill {
                        fill: #fff;
                    }

                    .primary-grey-fill {
                        fill: #ABADB7;
                    }
                }

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .mockup-phone {
            width: 230px;
            height: 470px;
            position: relative;
            background: url("../../assets/img/iPhone-mockup.png") no-repeat center center;
            background-size: cover;
            z-index: 8;
        }

    }

    .app-ready-rh {
        display: flex;
        width: 58%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1 {
            font-size: 56px;
            line-height: 64px;
            color: #232323;
            font-weight: 500;
            margin: 0 0 64px;

            b {
                font-weight: 900;
            }
        }

        a {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            outline: none;
            padding: 16px 79px;
            background-color: transparent;
            outline: none;
            box-shadow: none;
            color: #293254;
            border: 1px solid #293254;
            border-radius: 5px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 16px;
                left: 16px;
                width: 24px;
                height: 24px;
                background: url("../../assets/icons/ic_open_new_tab.svg") no-repeat center center;
            }

            &:hover {
                color: #fff;
                background: #293254;

                &:after {
                    background: url("../../assets/icons/ic_open_new_tab_white.svg") no-repeat center center;
                }
            }

        }

        .app-ready-rh-box {
            max-width: 525px;
            display: flex;
            flex-direction: column;
            align-items: center;



            &.includedCustomApp {
                max-width: 685px;

                h1 {
                    margin-bottom: 12px;
                }

                qrcode {
                    margin-top: 30px;
                }

                .app-ready-rh-inner-box {
                    p {
                        padding: 0px 185px;
                    }
                }
            }

            .button-true-green {
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                outline: none;
                background-color: #0FB87E;
                outline: none;
                box-shadow: none;
                margin-top: 72px;
                color: #fff;
                border-color: transparent;
                height: auto;
                padding: 17px 48px;

                &:after {
                    display: none;
                }

                &:hover {
                    border-color: #0E8D61;
                    background: #0E8D61;
                }
            }

            h4 {
                color: #232323;
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                margin: 24px 0 8px;
            }

            p {
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: #606060;
                margin: 0 0 24px;

            }

            .app-ready-rh-inner-box {
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 0px 105px;
                }
            }
        }
    }

    .close-modal {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    @media screen and (max-width: 991px) {

        .app-ready {
            overflow-y: auto;

            .app-ready-rh {
                justify-content: flex-start;
                padding: 80px 10px;

                h1 {
                    font-size: 42px;
                    line-height: 52px;
                    margin: 0 0 45px;
                }
            }

            .app-ready-rh-box {

                &.includedCustomApp {

                    .app-ready-rh-inner-box {
                        p {
                            padding: 0px 40px;
                        }
                    }
                }

                .button-true-green {
                    margin-top: 45px;
                    max-width: 400px;
                }
            }
        }




    }


    @media screen and (max-width: 768px) {
        .app-ready {
            .app-ready-rh {
                padding: 10px;
            }

        }
    }


    @media screen and (max-width: 667px) {

        .app-ready {
            flex-direction: column;

            .app-ready-rh,
            .app-ready-lf {
                padding-top: 40px;
                padding-bottom: 40px;
                width: 100%;
            }

            .app-ready-lf {
                order: 2;
            }

            .app-ready-rf {
                order: 1;
            }
        }

        .app-ready-rh {
            h1 {
                font-size: 36px !important;
                line-height: 42px !important;
                margin: 0 0 45px;
            }

            &-box {
                p {
                    padding: 0px 30px !important;
                }
            }
        }
    }

}
