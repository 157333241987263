.cdk-drag-preview {
    background-color: $color-true-white;
    border-right: 4px solid $color-primary-1;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

    .counter-wrapper {
        margin: 0 !important;
    }
}
.table-tbody-placeholder {
    height: 4px;
    width: 100%;
    background-color: $color-primary-1;
    transition: transform 500ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
    transition: transform 500ms cubic-bezier(0, 0, 0.2, 1);
}

.set-table-drag-list-container,
.week-table-drag-list-container {
    overflow: hidden;
}

.week-table-drag-list-container.cdk-drop-list-dragging
    .set-table-tbody:not(.cdk-drag-placeholder),
.set-table-drag-list-container.cdk-drop-list-dragging
    .set-table-tbody:not(.cdk-drag-placeholder) {
    transition: transform 500ms cubic-bezier(0, 0, 0.2, 1);
}
