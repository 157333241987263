@import '../abstracts/variables';

modal-center,
client-center,
resolution-center,
root-center,
cancellation-center,
coach-center {
    width: 100%;
    $color-lightgray: rgb(220, 220, 220);
    $border-radius: 8px;

    .column {
        display: flex;
        flex-direction: column;
    }

    .contact-dropdown-selected {
        display: inline-flex !important;
        width: auto !important;
    }


    .create-new-thread-heading {
        width: 100%;
        height: 65px;
        margin-top: 40px;
        display: flex;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        justify-content: space-between;
        align-items: center;
    }

    .button-true-green {
        z-index: 1000;
    }

    .attachment {
        padding: 8px 5px !important;
        border-radius: 5px;
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        background-color: #eeeeee;
        border: 1px solid #6060604D;

        img {
            cursor: pointer;
            opacity: .7;

            &:hover {
                opacity: 1;
            }
        }
    }

    .message-box,
    .client-messages-modal {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        margin-top: 64px;

        .link-back {
            display: none;

        }

        #new-thread-form {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .content-right {
            margin: 0 0 0 40px;
            width: 70%;
        }

        .content-left {
            width: 30%;
        }

        .content-left,
        .content-right {
            min-height: 500px;
            border-radius: 5px;

            #message-form {
                padding: 20px;
                display: flex;
                margin-top: 40px;
                background: #fff;
                flex-direction: column;
                border: 1px solid $color-lightgray;

                textarea {
                    min-height: 100px;
                    width: 100%;
                    border: 1px solid $color-lightgray;
                    color: rgb(63, 62, 62);
                    max-height: 120px;
                    margin-bottom: 15px;
                }
            }

            .load-more {
                margin: 0;
                position: absolute;
                top: 0;
                height: auto;
                left: 50%;
                transform: translateX(-50%);
            }

            .threads-list {
                background-color: #fff;
                border: 1px solid $color-lightgray;
                border-top: none;
                max-height: 664px;
                overflow-y: auto;
                height: calc(100% - 112px);
            }

            .content-body {
                padding: 30px 20px;
                display: flex;
                flex-direction: column;
                min-height: 300px;
                position: relative;
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;

                .message-list {
                    height: 450px;
                    position: relative;
                    overflow-y: auto;
                    background-color: #fff;
                    border: 1px solid $color-lightgray;
                    border-top: none;
                    padding: 10px 0;
                    position: relative;

                    .no-new-messages {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        align-items: center;

                        img {
                            width: 90px;
                        }

                        h6 {
                            margin-bottom: 10px;
                            font-size: 16px;
                        }

                        span {
                            max-width: 240px;
                        }
                    }
                }

                .no-conversations {
                    width: 100%;
                    height: 100%;
                    padding: 30px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    h6 {
                        margin: 15px 0;
                    }
                }

                .thread-item {
                    position: relative;
                }

                .thread-item,
                .contact-item {
                    padding: 15px 10px;
                    border: 1px solid $color-lightgray;
                    border-radius: 6px;
                    margin-bottom: 15px;
                    cursor: pointer;

                    h6 {
                        display: flex;
                        position: relative;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 10px;

                        div {
                            display: flex;
                            flex-direction: column;
                            align-self: flex-start;

                            span:first-child {
                                display: inline-flex;
                                margin: 5px 0px 16px 0px;
                            }
                        }

                        .thread-avatar {
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            min-width: 40px;
                            min-height: 40px;
                            margin-right: 10px;
                            display: flex;
                            align-self: center;
                            background-size: 75px;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                        }

                        .thread-no-avatar {
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            min-width: 40px;
                            min-height: 40px;
                            margin-right: 10px;
                            background-size: 40px;
                            display: flex;
                            align-self: center;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                        }

                        span:first-child {
                            color: #000;
                            font-weight: 600;
                        }

                        .noth-option-1 {
                            position: absolute;
                            right: 0px;
                            top: 0px;
                        }
                    }

                    &:hover {
                        background-color: rgb(245, 245, 245);
                    }
                }

                .contact-item {
                    padding: 14px 10px;

                    h6 {
                        margin-bottom: 0;
                    }
                }

                .selected {
                    border: 1px solid $color-lightgray;
                    background-color: rgb(238, 238, 238);

                    &:hover {
                        background-color: rgb(238, 238, 238);
                    }
                }

                form {
                    flex-direction: column;

                    .input-label {
                        padding: 0;
                        margin: 0;
                        display: block;
                        color: #232323;
                        font-size: 12px;
                        font-weight: 500;


                    }

                    div {
                        width: 100%;
                    }

                    .form-row {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;

                        input,
                        textarea,
                        ng-select {
                            margin: 8px 0px 40px;
                            width: 100%;
                            border: 1px solid #bfbfbf;
                            color: #232323;
                        }

                        textarea {
                            height: 130px;
                        }
                    }

                    button {
                        align-self: flex-end;
                    }
                }
            }

            span {
                color: rgb(109, 109, 109);
            }
        }

        .new-thread-heading,
        .conversation-heading {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            padding: 30px 20px;
            background-color: #fff;

            h5 {
                margin-bottom: 10px;
            }
        }

        .client-contacts {
            .conversation-heading {
                padding: 30px 20px 10px 20px;

                h5 {
                    margin-bottom: 10px;
                }
            }
        }

        .conversation-heading {
            border: 1px solid $color-lightgray;
        }

        .new-thread-heading {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            padding-bottom: 0;
            border: 1px solid $color-lightgray;
            border-bottom: none;

            h5 {
                margin: 0;
            }
        }

        .new-thread {
            height: calc(100% - 53px);
            background-color: #fff;
            border: 1px solid $color-lightgray;
            border-top: none;
        }

        .center-content {
            justify-content: center;
        }

        .thread-heading {
            padding: 30px;
            height: 85px;
            background-color: #fff;
            border: 1px solid $color-lightgray;
        }

        .message-text {
            width: 100%;
        }

        .messages-wrapper {
            display: flex;
            height: calc(100% - 83px);
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 !important;

            .message {
                padding: 25px 20px 25px 20px;
                border-bottom: 1px solid $color-lightgray;
                display: flex;

                .message-avatar {
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    border-radius: 50%;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    margin-right: 15px;
                    background-size: 45px;
                }

                h6 {
                    margin-bottom: 10px;
                    font-weight: 600;

                    span {
                        display: inline-block;
                        margin-left: 15px;
                    }
                }

                p {
                    color: gray;
                    font-weight: 500;
                    white-space: pre-wrap;
                }

                &:first-of-type {
                    margin-top: 100px;
                }
            }

            .message:last-child {
                border-bottom: none !important;
            }

            .indent {
                padding-left: 90px !important;
            }

            .attachments {
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: none;
                margin-bottom: 25px;
            }
        }
    }

    .input-label {
        margin-bottom: 8px;

        a {
            color: #232323 !important;
        }
    }

    .no-avatar {
        display: flex;
        flex-wrap: wrap;

        h6 {
            width: 100%;
        }
    }

    .visible {
        &-false {
            display: none;
        }

        &-true {
            display: block;
        }
    }

    .thread-has-new-message {
        border: 1px solid gray !important;
    }

    .client-messages-modal {
        margin-top: 0px;

        .content-right {
            width: 100%;
            margin-left: 0;

            #message-form {
                margin-top: 0;
            }
        }

        .thread-heading {
            display: flex;
            width: 100%;
            justify-content: space-between;

            img {
                opacity: .7;
                cursor: pointer;
                display: inline-block;
                margin-left: auto;

                &:hover {
                    opacity: 1;
                }
            }

            span {
                font-size: 20px;
                font-weight: 600;
                color: #000;
            }

        }
    }

    .search-wrapper {
        position: relative;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 5px;
        height: 48px;
        background-color: #EEEEEFE6;

        input {
            right: -25px;
            border-bottom: none;
        }

        label {
            right: calc(100% - 85px);
        }

        .button-clear {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }

        button {
            right: calc(100% - 70px);
        }
    }

    .no-search-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .no-search-result,
    .contact-dropdown {
        &-image {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            background-size: 75px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 12px;
            border-radius: 50%;
            background-position: 50% 50%;
            box-shadow: 0px 0px 13px -10px #707070;
        }

        &-selected {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: 3px 35px 3px 3px;
            border-radius: 4px;
            background-color: rgb(235, 235, 235);

            p {
                font-weight: 500;
            }

            p:last-child {
                position: absolute;
                opacity: .7;
                right: 10px;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                    font-weight: 700;
                }
            }

            span {
                width: 30px;
                height: 30px;
                background-size: 80px;
                background-repeat: no-repeat;
                display: inline-block;
                margin-right: 12px;
                border-radius: 50%;
                background-position: 50% 50%;
            }

        }



    }

    @media only screen and (max-width: 1201px) {
        .message-box {
            .content-right {
                width: 65%;
            }

            .content-left {
                width: 35%;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .message-box {
            flex-flow: column nowrap;

            .hide-section {
                display: none;
            }

            .link-back {
                display: inline-block;
                margin-bottom: 40px;
                font-size: 16px;
                color: #000;
                font-weight: 600;

                img {
                    display: inline-block;
                    margin-right: 5px;
                }
            }

            .content-right {
                margin: 0 0 40px 0;
                width: 100%;
            }

            .content-left {
                margin: 0 0 40px 0;
                width: 100%;
            }

            .threads-list {
                max-height: 500px;
            }
        }
    }
}

.attach-files {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;

    .progress {
        position: relative;

        .input-label {
            margin-top: 3px;
        }

        &-bar {
            left: 0;
            top: 0;
            position: absolute;
            background-color: red;
            height: 3px;
        }
    }

    input {
        visibility: hidden;
    }

    &:hover {
        opacity: 1;
    }

    p,
    label {
        width: 100%;
        display: flex;
        align-items: center;

        img {
            margin-right: 15px;
        }
    }

    .attached-item {
        background-color: #eeeeee;
        padding: 10px !important;
        margin-bottom: 8px !important;
        display: flex !important;
        width: 100%;
        justify-content: space-between;
        border: 1px solid #6060604D;
        border-radius: 5px;

        img {
            cursor: pointer;
            opacity: .8;

            &:hover {
                opacity: 1;
            }
        }
    }

    label {
        cursor: pointer;
        opacity: .7;
    }

    .required {
        color: red;
        display: inline-block;
    }
}

resolution-center-coach {
    .form-row {
        h5 {
            padding: 30px 30px 80px 30px;
            width: 100%;
        }
    }

    .sticky {
        min-height: 70px;
        align-items: center;
        flex-direction: row !important;

        @media only screen and (max-width: 768px) {
            flex-direction: column !important;
        }
    }

    .button-true-green {
        min-width: 165px !important;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }
}
