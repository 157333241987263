/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css',
'~@ng-select/ng-select/themes/default.theme.css',
'~ngx-toastr/toastr',
'~rrweb/dist/rrweb-all.min.css',

'style/abstracts/mixins',
'style/abstracts/variables',

'style/base/responsive',

'style/base/base',
'style/base/button',
'style/base/cdk',
'style/base/helpers',
'style/base/login',
'style/base/_ngxButtons',
'style/base/normalize',
'style/base/toast',
'style/base/profile',

'style/base/form',
'style/base/list',
'assets/modulator/modulator-styles',

'style/base/recording',

'style/base/theme',
'style/base/resolution',
'style/base/onboarding',
'style/base/calendar';
