.ng-select.select-input {
    min-height: 45px;
    margin: 8px 0 40px;
    outline: nene;

    &.ng-select-disabled {

        .ng-select-container,
        .ng-value-container {
            cursor: not-allowed;
        }
    }

    @include breakpoint(lg) {
        margin: 8px 0 16px;
    }

    &.ng-select-focused .ng-select-container {
        border-color: $color-table-th !important;
        box-shadow: none !important;
    }

    .ng-select-container {
        align-items: stretch;

        .ng-clear-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.ng-select-multiple .ng-select-container {
        height: 100%;

        .ng-value-container {
            flex-wrap: wrap;
            overflow: auto;
            padding-left: 0;
            padding-top: 0;

            .ng-value {
                background-color: $color-primary-transparent;
                border-radius: 5px;
                display: flex;
                padding: 6px 3px;

                .ng-value-icon {
                    border-right: none;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 14px;
                    opacity: 0.7;
                    order: 1;

                    &:hover {
                        background-color: $color-transparent;
                        opacity: 1;
                    }
                }
            }
        }

        .ng-clear-wrapper {
            margin: 0 3px 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ng-placeholder {
            top: 14px;
            padding-left: 10px;
        }
    }
    &.ng-select-single .ng-select-container {
        .ng-value-container {
            .ng-value {
                white-space: normal;
                overflow: unset;
                text-overflow: unset;
            }
        }
    }

    &.ng-select-single .ng-select-container,
    .ng-select-container {
        height: 100%;
        padding-left: 5px;

        .ng-value-container {
            font-size: 14px;

            .ng-placeholder {
                color: $color-table-th;
            }

            .ng-input {
                display: flex;
                position: relative !important;
                align-items: center;
                top: 0;
                padding: 0;
                width: auto !important;

                input {
                    margin: 0;
                }
            }
        }
    }

    .ng-arrow-wrapper {
        background-color: $color-primary-transparent;
        padding: 10px;
        padding-left: 12px;
        width: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ng-dropdown-panel {
        margin-top: 1px;

        .ng-dropdown-panel-items .ng-option {
            background-color: $color-true-white !important;
            border-left: 4px solid $color-transparent;
            color: $color-primary;
            padding: 15px 28px;
            margin-bottom: 0;
            white-space: normal;

            @include breakpoint(lg) {
                font-size: 14px;
            }

            &:hover {
                background-color: $color-box-shadow !important;
            }

            &.ng-option-selected {
                background-color: $color-box-shadow !important;
                border-left: 4px solid $color-primary-1;

                .ng-option-label {
                    font-weight: 400;
                }

                &:hover {
                    background-color: $color-box-shadow;

                    .ng-option-label {
                        font-weight: 500;
                    }
                }
            }

            &.ng-option-disabled {
                background-color: #eee !important;
                color: #aaa !important;
                cursor: not-allowed;
            }
        }
    }

    &.ng-invalid.ng-touched,
    &.form-submitted.ng-invalid {
        border-radius: 5px;
        border: 1px solid $color-error !important;

        .ng-select-container {
            border: none;
            border-radius: 5px;
        }
    }

    &.multiple-row {
        .ng-value {
            padding: 3px !important;

            .ng-select-value-box {
                display: flex;
                flex-direction: column;
            }

            p {
                margin: 0 !important;
            }
        }
    }
}
