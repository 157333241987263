.button {
    &-green {
        background-color: $color-primary-1;
        border: none;
        font-size: 14px;
        font-weight: 500;
        color: $color-true-white;
        padding: 10px 48px;

        &:disabled {
            background-color: $color-primary-1;
            opacity: 0.7;
            color: $color-primary-text-opacity;
        }
    }

    &-view {
        display: inline-block;
        width: 20px;
        height: 45px;
        background-image: url('../../assets/icons/ic_view.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 55%;
        opacity: .4;
        margin: 0 5px;

        &:hover {
            opacity: .7;
        }
    }

    &-switch {
        cursor: pointer;
        width: 75px;
        height: 33px;
        border-radius: 25px;
        position: relative;

        &-on {
            background-color: rgb(0, 176, 76);
            transition: all .2s ease-in-out;
        }

        &-off {
            background-color: rgb(236, 236, 236);
            -webkit-box-shadow: inset 0px 0px 2px 1px rgb(206, 206, 206);
            -moz-box-shadow: inset 0px 0px 2px 1px rgb(206, 206, 206);
            box-shadow: inset 0px 0px 2px 1px rgb(206, 206, 206);
            transition: all .2s ease-in-out;
        }

        &:hover {
            &::after {
                content: "";
                width: 75px;
                height: 33px;
                position: absolute;
                background-color: transparent;
                left: 0;
                top: 0;
                border-radius: 25px;
                -webkit-box-shadow: 0px 0px 5px 1px rgba(56, 56, 56, .6);
                -moz-box-shadow: 0px 0px 5px 1px rgba(56, 56, 56, .6);
                box-shadow: 0px 0px 5px 1px rgba(56, 56, 56, .6);
                z-index: 0;
            }
        }

        &-circle {
            position: absolute;
            margin: 0;
            padding: 0;
            width: 33px;
            height: 33px;
            top: 0px;
            left: 0px;
            border-radius: 50%;
            z-index: 1;
            -webkit-box-shadow: 0px 0px 4px 1px rgba(56, 56, 56, .6);
            -moz-box-shadow: 0px 0px 4px 1px rgba(56, 56, 56, .6);
            box-shadow: 0px 0px 4px 1px rgba(56, 56, 56, .6);
            background: #fff;
            transition: all .2s ease-in-out;

            &-on {
                transform: translateX(43px);

            }

            &-off {
                transform: translateX(0px);
            }
        }
    }

    &-library {
        background-color: rgba(128, 128, 128, .1);
        color: #232323;
        width: 45px;
        height: 45px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 0px 20px;

        img {
            height: 30px;
        }
    }

    &-report {
        width: 24px;
        height: 24px;
        background-image: url('../../assets/icons/ic_download_report.svg');
        background-repeat: no-repeat;

        &:hover {
            opacity: .7;
        }
    }

    &-report-with-text {
        background-image: url('../../assets/icons/ic_download_report.svg');
        background-repeat: no-repeat;
        background-position-y: 50%;
        padding: 10px 28px;

        &:disabled {
            opacity: .7;
        }
    }

    &-true-green {
        background-color: $color-primary-1;
        border: none;
        font-size: 14px;
        font-weight: 500;
        color: $color-true-white !important;
        min-width: 140px;
        padding: 10px 48px;

        @include breakpoint(lg) {
            font-size: 12px;
            min-width: 225px;
            padding: 10px 30px;
        }

        &-small {
            min-width: auto;
            padding: 10px 12px;
            background-color: $color-primary-1;
            border: none;
            border-radius: 5px;
            height: auto;
            margin: 5px 0;
            font-size: 14px;
            font-weight: 500;
            color: $color-true-white;
        }

        &:hover {
            color: $color-true-white !important;
        }
    }

    &-next {
        background-color: $color-primary-1;
        border: none;
        font-size: 14px;
        font-weight: 500;
        color: $color-true-white;
        min-width: 140px;
        padding: 10px 48px;

        @include breakpoint(xs) {
            width: 48px;
            height: 48px;
            padding: 0;
            min-width: 48px;
            min-height: 48px;
            max-width: 48px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-color: $color-primary-1;
                width: 100%;
                height: 100%;
                background-image: url('../../assets/icons/ic_arrow_right_white.svg');
                background-size: 24px 24px;
                background-position: 50% 50%;
                border-radius: 5px;
                background-repeat: no-repeat;
            }

            &:disabled {
                cursor: not-allowed;

                &:after {
                    background-color: #717171;
                }
            }
        }
    }

    &-back {
        background-color: $color-gray-button;
        border: none;
        color: $color-primary;
        font-size: 14px;
        font-weight: 500;
        min-width: 140px;
        padding: 10px 48px;

        @include breakpoint(xs) {
            position: relative;
            width: 48px;
            height: 48px;
            padding: 0;
            min-width: 48px;
            min-height: 48px;
            max-width: 48px;
            position: relative;
            border-radius: 5px;
            border: 1px solid #293254;
            background-color: transparent;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: contain;
                background-color: #efefef;
                background-repeat: no-repeat;
                background-image: url('../../assets/icons/ic_arrow_left_4.svg');
                background-size: 24px 24px;
                background-position: 50% 50%;
                border-radius: 5px;
            }
        }
    }

    &-gray {
        background-color: $color-gray-button;
        border: none;
        color: $color-primary;
        font-size: 14px;
        font-weight: 500;
        min-width: 140px;
        padding: 10px 48px;

        @include breakpoint(lg) {
            font-size: 12px;
            min-width: 225px;
            padding: 10px 30px;
        }
    }

    &-filter {
        background-color: $color-transparent;
        border: 1px solid $color-primary;
        font-size: 14px;
        font-weight: 400;
        color: $color-primary;
        min-width: 145px;
        padding: 10px 28px;
        margin-right: 12px;

        @include breakpoint(lg) {
            font-size: 12px;
            min-width: 225px;
            padding: 10px 30px;
        }

        img {
            margin-left: 10px;
            height: 16px;
        }

        &:hover {
            background-color: $color-primary-transparent;
        }
    }

    &-next {
        background-color: $color-transparent;
        border: 1px solid $color-primary;
        font-size: 14px;
        font-weight: 400;
        color: $color-primary;
        min-width: 145px;
        padding: 10px 48px;
        margin: 0 0 0 12px;

        @include breakpoint(lg) {
            font-size: 12px;
            min-width: 225px;
            padding: 10px 30px;
        }

        img {
            margin-left: 10px;
            height: 16px;
        }
    }

    &-status {
        &-green {
            height: 32px;
            background-color: $color-primary-1-transparent;
            border: 1px solid $color-success;
            border-radius: 5px;
            padding: 0;
            color: $color-success;
            min-width: 123px;

            @include breakpoint(lg) {
                font-size: 12px;
                min-width: 90px;
            }

            @include breakpoint(xs) {
                font-size: 11px;
                min-width: 62px;
                height: 52px;
                max-width: 65px;
                word-break: break-all;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 6;
            }
        }

        &-gray {
            height: 32px;
            background-color: #E3EAF6;
            border: 1px solid var(--Dark-Dark-600, #06080C);
            border-radius: 5px;
            padding: 0;
            color: $color-primary;
            min-width: 123px;

            @include breakpoint(lg) {
                font-size: 12px;
                min-width: 90px;
            }

            @include breakpoint(xs) {
                font-size: 11px;
                min-width: 62px;
                height: 52px;
                max-width: 65px;
                word-break: break-all;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 6;
            }
        }

        &-disabled {
            height: 32px;
            background-color: #EFF2F8;
            border: 1px solid var(--Grey-Grey-400, #868B94);
            border-radius: 5px;
            padding: 0;
            color: $color-primary;
            min-width: 123px;
            cursor: default !important;

            @include breakpoint(lg) {
                font-size: 12px;
                min-width: 90px;
            }

            @include breakpoint(xs) {
                font-size: 11px;
                min-width: 62px;
                height: 52px;
                max-width: 65px;
                word-break: break-all;
            }
        }

        &-verified {
            height: 32px;
            background-color: #0F815A;
            border: 1px solid #0F815A;
            border-radius: 5px;
            padding: 0;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            min-width: 123px;

            @include breakpoint(lg) {
                font-size: 12px;
                min-width: 90px;
            }

            @include breakpoint(xs) {
                font-size: 11px;
                min-width: 62px;
                height: 52px;
                max-width: 65px;
                word-break: break-all;
            }
        }

        &-not-verified {
            height: 32px;
            background-color: #333742;
            border: 1px solid #333742;
            border-radius: 5px;
            padding: 0;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            min-width: 123px;
            cursor: default !important;

            @include breakpoint(lg) {
                font-size: 12px;
                min-width: 90px;
            }

            @include breakpoint(xs) {
                font-size: 11px;
                min-width: 62px;
                height: 52px;
                max-width: 65px;
                word-break: break-all;
            }
        }

        &-pending-verification {
            height: 32px;
            background-color: #444473;
            border: 1px solid #444473;
            border-radius: 5px;
            padding: 0;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            min-width: 123px;

            @include breakpoint(lg) {
                font-size: 12px;
                min-width: 90px;
            }

            @include breakpoint(xs) {
                font-size: 11px;
                min-width: 62px;
                height: 52px;
                max-width: 65px;
                word-break: break-all;
            }
        }

        &-rejected {
            height: 32px;
            background-color: #E81523;
            border: 1px solid #E81523;
            border-radius: 5px;
            padding: 0;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            min-width: 123px;

            @include breakpoint(lg) {
                font-size: 12px;
                min-width: 90px;
            }

            @include breakpoint(xs) {
                font-size: 11px;
                min-width: 62px;
                height: 52px;
                max-width: 65px;
                word-break: break-all;
            }
        }

        &-active {
            height: 32px;
            background-color: #0F815A;
            border: 1px solid #0F815A;
            border-radius: 5px;
            padding: 0;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            min-width: 172px;
        }

        &-paused {
            height: 32px;
            background-color: #D2880A;
            border: 1px solid #D2880A;
            border-radius: 5px;
            padding: 0;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            min-width: 172px;
        }

        &-no-subscription {
            height: 32px;
            background-color: #494C54;
            border: 1px solid #494C54;
            border-radius: 5px;
            padding: 0;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            min-width: 216px;
        }
    }

    &-save {
        color: $color-primary-1;
        margin: 0 8px 0 0;
        padding: 4px 8px;
        height: 100%;

        &-filled {
            color: #fff;
            height: 40px;
            background-color: $color-primary-1;

            &:hover {
                background-color: $color-primary-1-opacity;
            }
        }
    }

    &-record {
        @include background-image("/../../assets/icons/ic_record",
            svg,
            15px auto,
            center,
            no-repeat);
        opacity: 0.6;
        height: 45px;
        margin: 0 $margin;

        &:hover {
            opacity: 1;
        }

        width: 20px;
    }

    &-edit {
        @include background-image("/../../assets/icons/edit",
            png,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        height: 45px;
        border: none;
        padding: 10px 10px 10px 10px;

        &:hover {
            opacity: .6;
        }

        width: 20px;

        &-templates {
            @include background-image("/../../assets/icons/file-edit-alt",
                png,
                15px auto,
                center,
                no-repeat);
            opacity: 0.6;
            margin: 0 $margin;

            &:hover {
                opacity: 1;
            }

            width: 20px;
        }
    }

    &-leaderboard-list {
        @include background-image("/../../assets/icons/ic_leaderboard_list",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        height: 45px;
        border: none;
        padding: 10px 10px 10px 10px;

        &:hover {
            opacity: .6;
        }

        width: 20px;
    }

    &-add-team-members {
        @include background-image("/../../assets/icons/ic_add_team_members",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        height: 45px;
        border: none;
        padding: 10px 10px 10px 10px;

        &:hover {
            opacity: .6;
        }

        width: 24px;
    }

    &-review {
        @include background-image("/../../assets/icons/ic_review_comment",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        height: 45px;
        border: none;
        padding: 10px 10px 10px 10px;

        &:hover {
            opacity: .6;
        }

        width: 20px;
    }

    &-info {
        @include background-image("/../../assets/icons/ic_info",
            svg,
            20px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        height: 45px;
        border: none;
        padding: 10px 10px 10px 10px;

        &:hover {
            opacity: .6;
        }

        width: 20px;
    }

    &-finance {
        @include background-image("/../../assets/icons/ic_advanced_home",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        height: 45px;
        border: none;
        padding: 10px 10px 10px 10px;

        &:hover {
            opacity: .6;
        }

        width: 20px;
    }

    &-hubspot {
        @include background-image("/../../assets/icons/ic_hubspot",
            svg,
            18px auto,
            center,
            no-repeat);

        opacity: 1;
        background-size: contain;
        margin: 0 $margin;
        height: 45px;
        border: none;
        padding: 10px 10px 10px 10px;

        &:hover {
            opacity: .6;
        }

        width: 20px;
    }

    &-recreate {
        @include background-image("/../../assets/icons/ic_refresh",
            png,
            15px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }

        width: 20px;
    }

    &-notification {
        @include background-image("/../../assets/icons/notification-gray",
            png,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }

        width: 20px;
    }

    &-like {
        @include background-image("/../../assets/icons/like",
            png,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }
    }

    &-comment {
        @include background-image("/../../assets/icons/comment",
            png,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }
    }

    &-copy {
        @include background-image("/../../assets/icons/ic_copy_grey",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }

        &-darker {
            @include background-image("/../../assets/icons/ic_copy_grey",
                svg,
                24px auto,
                center,
                no-repeat);
            opacity: 0.6;
            margin: 0 $margin;

            &:hover {
                opacity: 1;
            }
        }

    }

    &-copy-to {
        @include background-image("/../../assets/icons/ic_copy_to",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }
    }

    &-rate {
        @include background-image("/../../assets/icons/rate",
            png,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }
    }

    &-delete {
        @include background-image("/../../assets/icons/ic_delete",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 6;
        }
    }

    &-delete-message {
        @include background-image("/../../assets/icons/ic_delete_comment",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: .6;
        }
    }

    &-block {
        @include background-image("/../../assets/icons/ic_block_from_posting",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: .6;
        }
    }

    &-unblock {
        @include background-image("/../../assets/icons/ic_unblock_from_posting",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: .6;
        }
    }

    &-user {
        @include background-image("/../../assets/icons/users-all",
            png,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        padding-bottom: 8px;

        &:hover {
            opacity: .6;
        }
    }

    &-history {
        @include background-image("/../../assets/icons/history-icon",
            png,
            18px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0;
        padding-bottom: 0;

        &:hover {
            opacity: .6;
        }
    }

    &-add-reaction {
        @include background-image("/../../assets/icons/ic_add_reaction",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        padding-bottom: 8px;

        &:hover {
            opacity: .6;
        }
    }

    &-add-user {
        @include background-image("/../../assets/icons/add-user",
            png,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        padding-bottom: 8px;

        &:hover {
            opacity: .6;
        }
    }

    &-remove-user {
        @include background-image("/../../assets/icons/ic_user_delete",
            svg,
            19px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        padding-bottom: 8px;

        &:hover {
            opacity: .6;
        }
    }

    &-deactivate-to-all {
        @include background-image("/../../assets/icons/ic_deactivate_to_all_users",
            svg,
            19px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        padding-bottom: 8px;

        &:hover {
            opacity: .6;
        }
    }

    &-reactivate-to-all {
        @include background-image("/../../assets/icons/ic_reactivate_to_all_users",
            svg,
            19px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;
        padding-bottom: 8px;

        &:hover {
            opacity: .6;
        }
    }

    &-move {
        @include background-image("/../../assets/icons/ic_drag",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 1;
        margin: 0 $margin;

        &:hover {
            opacity: .6;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: .6;
        }
    }

    &-close {
        @include background-image("/assets/icons/clear",
            png,
            auto,
            center,
            no-repeat);
        position: relative;
        top: 0;
        margin: 0;
        background-color: $color-transparent;
        opacity: 1;
        width: 30px;
        height: 30px;
        transition: opacity 0.1s linear;

        &:hover {
            transition: opacity 0.5s linear;
            opacity: .6;
        }
    }

    &-check {
        @include background-image("/assets/icons/check-box",
            png,
            auto,
            center,
            no-repeat);
        position: relative;
        top: 0;
        margin: 0;
        background-color: $color-transparent;
        opacity: 1;
        width: 24px;
        height: 24px;
        transition: opacity 0.1s linear;
        border-radius: 50%;

        &:hover {
            transition: opacity 0.5s linear;
            opacity: .6;
        }
    }

    &-clear {
        @include background-image("/assets/icons/clear",
            png,
            auto,
            center,
            no-repeat);
        position: relative;
        top: 0;
        margin: 0;
        background-color: $color-transparent;
        opacity: 0;
        width: 30px;
        height: 30px;
        transition: opacity 0.1s linear;

        &.active {
            transition: opacity 0.5s linear;
            opacity: 1;
        }
    }

    &-more-info {
        position: relative;
        right: 15px;
        width: 40px;
        height: 31px;
        margin: 0;
        padding: 0;
        background-color: $color-transparent;
    }

    &-dual-left,
    &-dual-right {
        border: 1px solid $color-table-th;
        color: $color-table-th;
        margin: 8px 0 40px;
        padding: 10px 0;
        text-align: center;
        width: 50%;

        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }

        @include breakpoint(lg) {
            font-size: 14px;
            margin: 8px 0 16px;
        }

        &.selected-set-type {
            background-color: $color-true-white;
            border: 1px solid $color-primary;
            color: $color-primary;
        }
    }

    &-dual-left,
    &-tripple-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    &-dual-right,
    &-tripple-right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    &-tripple-left,
    &-tripple-middle,
    &-tripple-right {
        border: 1px solid $color-table-th;
        color: $color-table-th;
        margin: 8px 0 40px;
        padding: 10px 0;
        text-align: center;
        width: 33.33%;

        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }

        @include breakpoint(lg) {
            font-size: 14px;
            margin: 8px 0 16px;
        }

        &.selected-set-type {
            background-color: $color-true-white;
            border: 1px solid $color-primary;
            color: $color-primary;
        }
    }

    &-tripple-middle {
        border-radius: 0;
        border-left: none;
        border-right: none;
    }


    &-plus {
        @include background-image("/../../assets/icons/ic_plus",
            svg,
            24px auto,
            center,
            no-repeat);
        opacity: 0.6;
        margin: 0 $margin;

        &:hover {
            opacity: 1;
        }
    }

    &-plus-circle {
        background-image: url('../../assets/icons/ic_plus_circle.svg');
        background-repeat: no-repeat;
        background-position-y: 50%;
        color: $color-primary;
        padding: 10px 28px;

        &:disabled {
            opacity: 0.6;
        }
    }

    &-remove-circle {
        background-image: url('../../assets/icons/ic_remove_circle.svg');
        background-repeat: no-repeat;
        background-position-y: 50%;
        padding: 10px 28px;
    }

    &-pause-circle {
        background-image: url('../../assets/icons/ic_pause_circle.svg');
        background-repeat: no-repeat;
        background-position-y: 50%;
        padding: 10px 28px;
    }

    &-resume-circle {
        background-image: url('../../assets/icons/ic_resume_circle.svg');
        background-repeat: no-repeat;
        background-position-y: 50%;
        padding: 10px 28px;
    }

    &-modal {
        &-green {
            height: 46px;
            background-color: $color-transparent;
            border: 1px solid $color-primary-1;
            border-radius: 5px;
            padding: 10px 48px;
            color: $color-primary-1;
            min-width: 123px;

            &.disabled,
            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

    }

    &-link {
        padding: 5px 12px 5px 32px;
        color: #232323;
        font-size: 14px;
        text-decoration: underline;
        margin: 0;
    }

    &-fake {
        background-color: $color-primary-1;
        border: 1px solid $color-primary-1;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: $color-true-white !important;
        min-width: 140px;
        padding: 12px 48px;
        margin-left: 10px;

        @include breakpoint(lg) {
            font-size: 12px;
            min-width: 225px;
            padding: 10px 30px;
        }

        &:hover {
            color: $color-true-white !important;
        }
    }
}

.button-status-icon {

    &-locked {
        border: none;
        background-size: contain;
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        opacity: .5;
        background-image: url('../../assets/icons/ic_locked.svg');
    }

    &-unlocked {
        border: none;
        background-size: contain;
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        opacity: 0;
    }

}

.filter-checkboxes {
    display: flex;
    align-items: center;

    .checkbox-label {
        cursor: pointer;
        color: #232323;
    }

    .input-checkbox {

        &.true,
        &.false {
            cursor: pointer;
            border: 1px solid $color-primary-1;
            border-radius: 2px;
            transition: background-color 0.2s linear;
        }

        &.true {
            @include flex(flex, row, nowrap, center, center);
            @include background-image('/assets/icons/check-box',
                png,
                10px auto,
                center,
                no-repeat);
            background-color: $color-primary-1;
            transition: background-color 0.2s linear;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}

.checkbox {
    border: 1px solid $color-true-white;
    border-radius: 1px;
    height: 18px;
    width: 18px;

    &-label {
        font-size: 14px;
        margin: 0 10px;
        color: $color-gray;
    }
}

.pagination {
    @include flex(flex, row, nowrap, center, center);

    .page-item {
        &.disabled .page-link {
            color: $color-gray;
        }

        &.active .page-link {
            background-color: $color-primary-1;
            border-color: $color-primary-1;
            color: #fff;
        }
    }

    .page-link {
        padding: 10px 20px;

        @include breakpoint(lg) {
            padding: 10px 16px;
            font-size: 12px;
        }

        &:hover {
            color: $color-primary-1;
            background-color: $color-hr;
        }
    }
}

.badge {

    &-program,
    &-blog,
    &-gray {
        p {
            border: none;
            background-color: $color-primary;
            border-radius: 20px;
            color: $color-true-white;
            cursor: pointer;
            max-width: 100px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            padding: 5px;
        }
    }

    &-blog {
        p {
            background-color: $color-primary-1;
            color: $color-true-white;
        }
    }

    &-gray {
        p {
            background-color: $color-table-th;
            color: $color-true-white;
        }
    }
}

.emerald {
    .page-list-wrapper {
        .overflow-table {
            .badge {

                &-program,
                &-blog,
                &-gray {
                    p {
                        border: none;
                        background-color: $color-primary;
                        border-radius: 20px;
                        color: $color-true-white;
                        cursor: pointer;
                        max-width: 100px;
                        font-weight: 400;
                        font-size: 14px;
                        text-align: center;
                        padding: 5px;
                    }
                }

                &-blog {
                    p {
                        background-color: $color-primary-green;
                        color: $color-true-white;
                    }
                }

                &-gray {
                    p {
                        background-color: $color-table-th;
                        color: $color-true-white;
                    }
                }
            }
        }
    }

}

.no-click {
    cursor: default !important;
}

.pointer {
    cursor: pointer;
}

.tub-label {
    color: $color-primary-1;
    padding: 10px 38px;
    border: 1px solid $color-primary-1;
    outline: none;

    &.active {
        background: $color-primary-1;
        color: white;
    }

    &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.chart-switch {
    background: #D5DCE9;
    border: 4px solid #D5DCE9;
    padding: 20px 40px !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: var(--Dark-Dark-500---Main, #0C0F15);
    white-space: nowrap;

    &.active {
        background: #175CFF;
        color: white;
    }

    &:last-of-type {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}

@media (max-width: 766px) {
    .pagination {
        flex-flow: row wrap;
    }
}