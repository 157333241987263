@import "../abstracts/variables";

.emerald {
    .table-v4 {

        &-xs,
        &-sm,
        &-md,
        &-lg,
        &-xlg {
            width: 100% !important;
            margin-bottom: 40px;

            thead tr th {
                padding: 5px 10px;
            }

            tbody {
                padding: 0;

                tr {
                    height: 50px;
                    margin-bottom: 15px;
                    vertical-align: middle;

                    td {
                        padding: 4px 16px;

                        span {
                            word-break: break-word;
                        }

                        input[type="text"],
                        input[type="number"],
                        input[type="password"],
                        input[type="email"] {
                            width: 100% !important;
                        }
                    }

                    .actions {
                        display: inline-flex;
                        flex-flow: row wrap;
                        width: 100%;
                        height: 100%;
                        justify-content: flex-start;
                        align-items: center;
                        padding-right: 10px;

                        a,
                        button {
                            display: inline-block;
                            height: 20px;
                            width: 20px;
                            margin: 4px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

.emerald {

    .badge-blog,
    .badge-program,
    .badge-gray {
        border: none;
        background-color: $color-primary;
        color: $color-true-white;
        border-radius: 20px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        cursor: default;
        width: 120px;
        margin: 0;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .badge-blog {
        background-color: $color-primary-1;
        color: $color-true-white;
    }

    .badge-gray {
        background-color: $color-table-th;
        color: $color-true-white;
    }

    .button {
        &-assign {
            &-package {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 36px;
                width: 85px;
                padding: 0px 6px;
                border-radius: 6px;

                button {
                    opacity: 1;
                }

                &:hover {
                    background-color: $color-gray-button;
                    cursor: pointer;
                }
            }
        }

        &-notification {

            position: relative;

            span {
                position: absolute;
                width: 16px;
                height: 16px;
                justify-content: center;
                display: flex;
                background-color: red;
                color: #fff;
                font-weight: 600;
                border-radius: 50%;
                font-size: 12px;
                align-items: center;
            }
        }

        &-edit {
            &-admin {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 36px;
                padding: 0px 6px;
                border-radius: 6px;
                text-decoration: none;

                button {
                    opacity: 1;
                }

                &:hover {
                    background-color: $color-gray-button;
                    cursor: pointer;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .emerald {
        .table-v4 {
            &-xs {
                thead {
                    display: none;
                }

                tbody {
                    display: block;
                    width: 100%;

                    tr {
                        display: block;
                        width: 100%;
                        height: auto;
                        box-shadow: 0px 0px 13px -10px #707070;

                        td {
                            display: block;
                            position: relative;
                            word-break: break-word;
                            width: 100%;
                            padding: 10px 16px 10px 50% !important;
                            text-align: right;
                            border-bottom: 1px solid $color-border-light;

                            .page-list-td-image,
                            .badge-blog,
                            .badge-gray,
                            .button-assign-package {
                                margin-left: auto;
                            }

                            .page-list-td-actions-wrapper {
                                flex-direction: row !important;
                                justify-content: flex-end;

                                a,
                                button {
                                    height: 25px;
                                }
                            }

                            .button-notification {
                                height: 25px;
                            }

                            .button-status-green,
                            .button-status-gray {
                                max-width: 100%;
                                height: 32px;
                                min-width: 90px;
                                margin: 0;
                            }

                            .button-edit-admin {
                                justify-content: flex-end;
                            }

                            &:before {
                                content: attr(data-label);
                                width: 50%;
                                position: absolute;
                                left: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                padding: 10px 8px 10px;
                                text-align: left;
                                font-weight: 600;
                            }
                        }

                        .actions {
                            justify-content: flex-end !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 667px) {
    .emerald {
        .table-v4 {
            &-sm {
                thead {
                    display: none;
                }

                tbody {
                    display: block;
                    width: 100%;

                    tr {
                        display: block;
                        width: 100%;
                        height: auto;
                        box-shadow: 0px 0px 13px -10px #707070;

                        td {
                            display: block;
                            position: relative;
                            width: 100%;
                            padding: 10px 16px 10px 50% !important;
                            word-break: break-word;
                            text-align: right;
                            border-bottom: 1px solid $color-border-light;

                            .page-list-td-image,
                            .badge-blog,
                            .badge-gray,
                            .button-assign-package {
                                margin-left: auto;
                            }


                            .page-list-td-actions-wrapper {
                                flex-direction: row !important;
                                justify-content: flex-end;

                                a,
                                button {
                                    height: 25px;
                                }
                            }

                            .button-notification {
                                height: 25px;
                            }

                            .button-status-green,
                            .button-status-gray {
                                max-width: 100%;
                                height: 32px;
                                min-width: 90px;
                                margin: 0;
                            }

                            .button-edit-admin {
                                justify-content: flex-end;
                            }

                            &:before {
                                content: attr(data-label);
                                width: 50%;
                                position: absolute;
                                left: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                padding: 10px 8px 10px;
                                text-align: left;
                                font-weight: 600;
                            }
                        }

                        .button-true-green {
                            min-width: 140px !important;
                        }

                        .actions {
                            justify-content: flex-end !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .emerald {

        .badge-blog,
        .badge-program,
        .badge-gray {
            padding: 5px 2px;
            width: 90px;
        }

        .table-v4 {
            &-md {
                thead {
                    display: none;
                }

                tbody {
                    display: block;
                    width: 100%;

                    tr {
                        display: block;
                        width: 100%;
                        height: auto;
                        box-shadow: 0px 0px 13px -10px #707070;

                        td {
                            display: block;
                            position: relative;
                            width: 100%;
                            word-break: break-word;
                            padding: 10px 16px 10px 50% !important;
                            text-align: right;
                            border-bottom: 1px solid $color-border-light;

                            .page-list-td-image,
                            .badge-blog,
                            .badge-gray,
                            .button-assign-package {
                                margin-left: auto;
                            }

                            .page-list-td-actions-wrapper {
                                flex-direction: row !important;
                                justify-content: flex-end;

                                a,
                                button {
                                    height: 25px;
                                }
                            }

                            .button-true-green {
                                min-width: 130px !important;
                            }

                            .button-notification {
                                height: 25px;
                            }

                            .button-status-green,
                            .button-status-gray {
                                max-width: 100%;
                                height: 32px;
                                min-width: 90px;
                                margin: 0;
                            }

                            .button-edit-admin {
                                justify-content: flex-end;
                            }

                            &:before {
                                content: attr(data-label);
                                width: 50%;
                                position: absolute;
                                left: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                padding: 10px 8px 10px;
                                text-align: left;
                                font-weight: 600;
                            }
                        }

                        .actions {
                            justify-content: flex-end !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .emerald {
        .table-v4 {
            &-lg {
                thead {
                    display: none;
                }

                tbody {
                    display: block;
                    width: 100%;

                    tr {
                        display: block;
                        width: 100%;
                        height: auto;
                        box-shadow: 0px 0px 13px -10px #707070;

                        td {
                            display: block;
                            position: relative;
                            width: 100%;
                            padding: 10px 16px 10px 50% !important;
                            text-align: right;
                            border-bottom: 1px solid $color-border-light;

                            .page-list-td-image,
                            .badge-blog,
                            .badge-gray,
                            .button-assign-package {
                                margin-left: auto;
                            }

                            .page-list-td-actions-wrapper {
                                flex-direction: row !important;
                                justify-content: flex-end;

                                a,
                                button {
                                    height: 25px;
                                }
                            }

                            .button-notification {
                                height: 25px;
                            }

                            .button-status-green,
                            .button-status-gray {
                                max-width: 100%;
                                height: 32px;
                                min-width: 90px;
                                margin: 0;
                            }

                            .button-edit-admin {
                                justify-content: flex-end;
                            }

                            &:before {
                                content: attr(data-label);
                                width: 50%;
                                position: absolute;
                                left: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                padding: 10px 8px 10px;
                                text-align: left;
                                font-weight: 600;
                            }
                        }

                        .actions {
                            justify-content: flex-end !important;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 1400px) {
    .emerald {
        .table-v4 {
            &-xlg {
                thead {
                    display: none;
                }

                tbody {
                    display: block;
                    width: 100%;

                    tr {
                        display: block;
                        width: 100%;
                        height: auto;
                        box-shadow: 0px 0px 13px -10px #707070;

                        td {
                            display: block;
                            position: relative;
                            width: 100%;
                            padding: 10px 16px 10px 50% !important;
                            text-align: right;
                            border-bottom: 1px solid $color-border-light;

                            .page-list-td-image,
                            .badge-blog,
                            .badge-gray,
                            .button-assign-package {
                                margin-left: auto;
                            }

                            .page-list-td-actions-wrapper {
                                flex-direction: row !important;
                                justify-content: flex-end;

                                a,
                                button {
                                    height: 25px;
                                }
                            }

                            .button-notification {
                                height: 25px;
                            }

                            .button-status-green,
                            .button-status-gray {
                                max-width: 100%;
                                height: 32px;
                                min-width: 90px;
                                margin: 0;
                            }

                            .button-edit-admin {
                                justify-content: flex-end;
                            }

                            &:before {
                                content: attr(data-label);
                                width: 50%;
                                position: absolute;
                                left: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                padding: 10px 8px 10px;
                                text-align: left;
                                font-weight: 600;
                            }
                        }

                        .actions {
                            justify-content: flex-end !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .emerald {

        .badge-blog,
        .badge-program,
        .badge-gray {
            padding: 5px 2px;
            width: 90px;
        }
    }
}

@media only screen and (min-width: 992px) {
    .emerald {

        .badge-blog,
        .badge-program,
        .badge-gray {
            padding: 10px 5px;
            width: 90px;
        }
    }
}
