.toast-custom {
    &-error {
        background: $color-error url('/assets/icons/toast-error_2x.png');
    }
    &-success {
        background: $color-success url('/assets/icons/toast-success_2x.png');
    }
    &-warning {
        background: $color-warning url('/assets/icons/toast-warning_2x.png');
    }
    &-info {
        background: $color-warning url('/assets/icons/toast-warning_2x.png');
    }
}
.toast-container .ngx-toastr {
    border-radius: 5px;
    padding: 15px 15px 15px 55px;
    box-shadow: none;
}
.toast-title {
    font-weight: 500;
    letter-spacing: 1.1px;
}
.toast-message {
    font-weight: 300;
    font-size: 13px;
}
