@media only screen and (max-width: 1680px) {
    app-home {
        .page-list {
            .nav-home-container {
                flex: 0 0 33%;
                max-width: 33%;
                margin-bottom: 30px;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    app-root {
        .content {
            .button-true-green {
                padding: 10px 20px;
            }

            .tub-label {
                padding: 10px 20px;

                &:first-of-type {
                    min-width: 100px;
                }
            }

            .page-list {
                .page-list-headline {
                    margin-bottom: 24px;
                    flex-direction: column;
                }

                .page-list-title {
                    width: 100%;
                    margin-bottom: 15px;
                }

                .page-list-options {
                    width: 100%;
                    flex-wrap: nowrap;

                    .search-wrapper {
                        display: flex;
                        width: 100%;
                        max-width: 390px;

                        input {
                            width: 100%;
                            right: 0;
                            padding-right: 5px;
                        }

                        label {
                            right: calc(100% - 57px);
                        }
                    }
                }

                .nav-home-container {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                .filter-row {
                    flex-wrap: wrap !important;
                }

                .filter-row-input {
                    width: 45%;
                }

                .page-list-td-shorter-text p {
                    width: 130px;
                }
            }

            .create-page {
                .week-wrapper {
                    padding-right: 0;
                    overflow: auto;
                    -webkit-overflow-scrolling: touch !important;
                    width: 100%;

                    .week-header {
                        padding: 8px 22px;
                    }

                    .week-table {
                        padding: 0;
                    }

                    .week-table-thead {
                        padding: 22px 0;
                    }

                    .days-item-title {
                        padding: 0;
                    }

                    .week-table-tbody-input {
                        padding: 10px 0 0;
                    }
                }

                .week-header {
                    padding: 8px 0px;
                }

                .week-table-thead {
                    padding: 22px 0;
                }

                .days-item-title {
                    padding: 0;
                }

                .week-table-tbody-input {
                    padding: 10px 0 0;
                }

                .create-page-headline {
                    flex-direction: column;
                    align-items: flex-start;

                    h4 {
                        margin-bottom: 15px;
                    }

                    .create-page-options {
                        align-self: flex-end;
                    }
                }

                .create-page-content {
                    flex-wrap: wrap;

                    form {
                        flex-wrap: wrap;
                    }

                    .create-page-content-left-wrapper {
                        width: 100%;
                        margin-right: 0;
                    }

                    .create-page-content-left {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 12px;
                        padding: 22px 22px 8px 22px;

                        .form-row-input-double {
                            .form-row-input {
                                &.center {
                                    &.width-30 {
                                        flex-direction: column;
                                        align-items: flex-start;

                                        span {
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .create-page-content-right {
                        width: 100%;
                        height: auto;
                        padding: 22px 22px 8px 22px;
                    }
                }
            }

            translate-list {
                .page-list {
                    td {
                        .input {
                            min-width: auto;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {

    .profile-stepper-modal {
        max-width: 100%;
        margin: 0;
        padding-left: 0;
        height: 100vh;
        top: 0 !important;

        .modal-content {
            height: 100vh;
            display: block;
            max-width: 100% !important;
            overflow: hidden;

            .stepper {
                width: 100%;
                max-width: 1200px;
                top: 10px;
                left: 50%;
                transform: translateX(-50%);
                position: fixed;
                height: calc(100% - 20px);

                .brand-name-wrapper {
                    position: fixed;
                    width: 100%;
                    top: 0;
                }

                .stepper-header-wrapper {
                    position: fixed;
                    top: 60px;
                }

                .stepper-content {
                    position: fixed;
                    top: 128px;
                    height: calc(100vh - 233px);
                    overflow: hidden;

                    @media only screen and (max-width: 768px) {
                        height: calc(100vh - 208px);
                        top: 116px;
                    }

                    @media only screen and (max-width: 567px) {
                        height: calc(100vh - 208px);
                        top: 108px;
                    }

                }

                .stepper-pagination {
                    position: fixed;
                    bottom: 0;
                }
            }
        }
    }


    app-root {
        display: block;
        height: 100%;

        .content {
            translate-list {
                .page-list {
                    td {
                        .input {
                            width: 330px;
                        }
                    }
                }
            }

            .page-list {
                .page-list-wrapper {
                    padding: 26px 12px 30px;
                    margin-bottom: 80px;
                }

                .nav-home-container {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .page-list-headline {
                    margin-bottom: 0;
                }

                table {
                    margin-top: 10px !important;
                }

                .page-list-options {
                    flex-direction: column;

                    .search-wrapper {
                        margin: 0 0 10px;
                        position: relative;
                        max-width: 100%;

                        input {
                            max-width: 100%;
                            min-width: 100%;
                        }

                        label {
                            right: calc(100% - 34px);
                            position: absolute;
                        }

                        .button-clear {
                            position: absolute;
                            right: 0;
                        }
                    }

                    .button-filter {
                        width: 100%;
                        margin-right: 0;
                    }

                    .button-true-green {
                        width: 100%;
                    }
                }

                .filter-row {
                    margin-top: 10px;
                }

                .filter-row-input {
                    width: 100% !important;
                }

                .filter-row-input-number {
                    width: 100% !important;
                }
            }

            .create-page {
                program-week {
                    .create-page-content-left.week {
                        .week-table-thead {
                            .week-table-thead-th.width-70 {
                                width: 100%;
                            }
                        }
                    }

                    .meal-item-box {
                        flex-direction: column;
                    }
                }

                .set-header {
                    padding: 0 0 8px 0;
                }

                .set-body {
                    padding: 8px 0px;
                }

                .set-table {
                    overflow-x: auto;
                    min-width: 100%;
                    width: auto;
                    overflow-y: auto;
                    
                    .set-table-drag-list-container {
                        width: auto;
                        min-width: 103%;
                        -webkit-overflow-scrolling: touch !important;
                        overflow: auto;
                    }

                    .set-table-thead {
                        padding: 22px 0;
                        width: auto;

                        .width-20 {
                            width: 110px;
                        }
                    }
                }

                .set-table-tbody-rest {
                    padding: 22px 5px;
                }

                .set-table-tbody-exercise {
                    padding: 22px 5px 0;

                    .exercise-item-wrapper {
                        .width-20 {
                            width: 117px;
                            margin-right: 0;
                        }
                    }
                }

                .week-table-thead {
                    &.week-description {
                        flex-direction: column;
                        align-items: flex-start;

                        p {
                            margin-bottom: 10px;
                            width: 100% !important;
                        }
                    }

                    .week-table-thead-th.width-70 {
                        width: 50%;
                    }

                    .group {
                        width: 50%;
                    }
                }

                .week-table-tbody-input {
                    .input-group-item {
                        margin-right: 0;
                    }
                }

                .create-page-headline {
                    .create-page-options {
                        width: 100%;

                        .side-buttons {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            button {
                                margin: 0;
                            }
                        }
                    }
                }

                .create-page-content {
                    .create-page-content-left {
                        &.week {
                            .week-header {
                                padding: 8px 0;
                            }
                        }

                        .form-row-input-double {
                            margin-left: 0;
                            width: 100%;

                            .form-row-input {
                                &.center {
                                    margin: 0 0 10px;

                                    &.width-30 {
                                        flex-direction: row;
                                        align-items: center;

                                        span {
                                            margin: 0 10px;
                                        }
                                    }
                                }
                            }
                        }

                        .guides-steps {
                            padding: 22px;
                            left: -22px;
                            width: calc(100% + 44px);

                            .button-modal-green {
                                width: 100%;
                            }

                            &+.button-modal-green {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .pagination {
            flex-wrap: wrap;
        }

        .form-row-input {
            width: 100% !important;
            margin-right: 0 !important;
            margin-left: 0 !important;
            margin-top: 0 !important;
            margin-bottom: 10px !important;
        }
    }

    modal-container {
        exercise-modal {
            .pagination {
                flex-wrap: wrap;
            }

            .modal-body-imgs {
                height: 380px !important;

                &>div {
                    width: 100%;
                }

                .modal-body-box {
                    width: 100%;
                    margin-right: 0;
                }
            }

            .modal-body-options {
                width: 100%;
                flex-direction: column !important;

                .search-dropdown {
                    width: 100%;
                    padding: 0 12px 0 0px;
                }

                .search-wrapper {
                    position: relative;

                    label {
                        left: 27px !important;
                        top: 8px !important;
                    }
                }
            }
        }

        app-gallery-modal {
            .gallery-body-imgs {
                height: 420px !important;
            }

            .gallery-body-pagination {
                padding: 10px 0 0 !important;
                flex-wrap: wrap;
            }

            form {
                width: 100%;
            }

            .gallery-body-imgs-add {
                width: 100% !important;
                margin-right: 0 !important;
                align-items: center !important;
                flex-direction: column !important;
            }

            .gallery-item-box {
                width: 100%;
                display: flex;

                .gallery-body-box {
                    width: 100%;
                    margin-right: 0;
                }
            }

            .search-wrapper {
                .button-clear {
                    right: 50px !important;
                }

                .text-input {
                    width: 100% !important;
                }
            }
        }
    }

    modal-container {
        .workout-edit-modal {
            max-width: 100%;

            .create-page-content-left-wrapper {
                width: 100%;
                margin-right: 0;

                .create-page-content-left {

                    padding: 22px 22px 8px 22px;
                    margin-right: 0;

                    .form-row {
                        flex-direction: column;

                        .form-row-input {
                            margin-right: 0;
                            width: 100%;
                            margin-left: 0;

                            &.center {
                                margin: 0 0 10px;
                            }
                        }

                        .input-label {
                            align-self: flex-start;
                        }
                    }
                }
            }

            .create-page-content-right {
                width: 100%;
                padding: 22px 22px 8px 22px;
            }
        }

        app-workout-edit-modal {
            .set-header {
                padding: 0 0 8px 0 !important;
            }

            .set-body {
                padding: 8px 0 !important;
            }

            .create-page-content-left {

                .set-table {
                    overflow-x: auto;

                    .set-table-thead {
                        width: auto;
                        padding: 22px 5px;

                        .set-table-thead-th.width-20,
                        .set-table-thead-th.width-10 {
                            width: 110px;
                        }
                    }

                    .set-table-drag-list-container {
                        width: auto;
                    }

                    .set-table-tbody {
                        .set-table-tbody-exercise {
                            width: auto;
                            padding: 22px 5px 0;

                            .exercise-item-wrapper {
                                .width-20 {
                                    width: 117px;
                                    margin-right: 0;
                                }

                                .width-10 {
                                    width: 117px;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        meal-modal {
            .modal-wrapper {
                .modal-body-wrapper {
                    .modal-body-options {
                        width: 100%;

                        .search-wrapper {
                            width: 100% !important;
                        }
                    }

                    .modal-body-imgs {
                        &>div {
                            width: 100%;

                            .modal-body-box {
                                width: 100%;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .profile-stepper-modal {
        margin: 0 !important;
    }

    profile-stepper {


        .stepper {
            &-header {
                padding: 0px 14px 12px 14px !important;

                &--item {
                    img {
                        max-height: 32px;
                        margin-right: 8px !important;
                    }

                    .step-text {

                        span,
                        h5 {
                            font-size: 14px !important;
                        }
                    }
                }
            }

            &-content {
                padding: 10px !important;

                &-wrapper {
                    margin: 0 !important;
                }

                h4 {
                    font-size: 24px !important;
                }
            }

            &-paggination {
                .right-buttons {

                    .close-button {
                        min-width: 80px;

                    }

                    .skip-button {
                        min-width: 80px;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 576px) {
    .profile-stepper-modal {
        margin: 0 !important;
    }

    profile-stepper {

        .stepper {
            &-header {
                padding: 0px 10px 12px 30px !important;


                &--item {
                    width: auto;
                    margin-right: 16px;
                    img {
                        max-height: 26px;
                        margin-right: 4px !important;
                    }

                    .step-text {
                        padding-bottom: 5px;

                        span,
                        h5 {
                            font-size: 12px !important;
                        }
                    }
                }
            }

            &-content {
                padding: 10px;

                &-wrapper {
                    margin: 0;
                }

                h4 {
                    font-size: 18px !important;
                    margin-top: 22px;
                    margin-left: 20px;
                }
            }

            &-paggination {
                .left-buttons {
                    button {
                        margin-right: 0 !important;
                    }
                }

                .right-buttons {
                    text-align: center;
                    display: flex;

                    .back-button {
                        margin-right: 0 !important;
                    }

                    .close-button {
                        margin-right: 0 !important;
                    }

                    .skip-button {
                        margin-right: 15px !important;
                    }

                }
            }
        }
    }

    .app-visuals {
        flex-direction: column;
        padding-left: 0px !important;

        .images {
            .descriptor {
                bottom: -55px !important;
            }
        }

    }

}

@media only screen and (max-width: 450px) {
    .stepper {
        &-header {
            &--item {
                img {
                    display: none !important;
                }

            }
        }


    }
}