body {
    background-color: $color-background;
    color: $color-text;
    font-display: optional;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight-normal;
    line-height: $line-height;
    overflow: hidden;
    text-align: left;
    touch-action: pan-x pan-y;
}

div.iti {
    width: 100%;
    margin-bottom: 10px;
}

h1,
h2 {
    color: $color-heading;
    line-height: 1.1;
    margin: $margin 0;
}

h3,
h4,
h5,
h6 {
    color: $color-primary;
    line-height: 1.1;
    margin: $margin 0;
}

h3 {
    margin: 10px 0 10px 0;
}

h4,
h5,
h6 {
    margin: 0px 0px;
}

h1 {
    font-size: 50px;
}

h2 {
    font-size: 38px;
}

h3 {
    font-size: 35px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 14px;
}

a {
    transition: all 0.3s ease;
    text-decoration: none;

    &:link,
    &:visited {
        color: $color-link-default;
    }

    &:hover,
    &:active {
        color: $color-link-hover;
    }

    &:focus {
        outline: none;
    }
}

p {
    margin: $margin 0;
}

b,
bold,
strong {
    font-weight: $font-weight-bold;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-hr;
    margin: $margin auto;
    padding: 0;
}

.modal-lg {
    &.camera-modal {
        top: 10px !important;
        margin: 0 auto;
    }

    &.calendar-event-modal {
        top: 10px !important;
    }
}

.modal {
    background-color: $color-primary-transparent-modal;

    &-dialog {
        top: calc(50% - 250px) !important;

        &.modulator-preview-modal {
            top: 20px !important;
        }

        @media only screen and (min-width: 576px) {
            max-width: 600px;
        }
    }

    &-content {
        border-radius: 5px;
        border: none;
    }

    .meal-modal {
        top: 0 !important;
        max-width: 775px;
    }
}

.gallery-body {
    .progress-bar {
        background-color: #175cff;
    }
}

.gallery-modal,
.gallery-video-crop-modal,
.exercise-modal {
    max-width: 775px;
    top: 0px !important;

    .modal-content {
        background-color: $color-transparent;
        height: 100%;
        width: 100%;

        .modal-header {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .modal-body {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

.workout-edit-modal {
    max-width: 80%;
    display: flex;
    justify-content: center;
    top: 50% !important;
    transform: translateY(-50%) !important;
    overflow-y: hidden;
    padding: 40px 0;

    .create-page-headline {
        justify-content: flex-end !important;
    }

    .create-page-title {
        display: none !important;
    }

    .create-page-content-left {
        width: 100% !important;
    }

    .drag-list-container {
        width: 100% !important;
    }

    .modal-content {
        max-width: 100% !important;
    }
}


.card {
    padding: 0;
    background-color: transparent;
    border-bottom: none;
    border-radius: 0 !important;
}

.home-icon {

    .a {
        stroke: none;
    }

    .b {
        stroke: inherit;
    }
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: $color-primary-1;
    color: #fff !important;
}

.card-header {
    padding: 0;
    background-color: transparent;
    border-bottom: none;
    border-radius: 0 !important;

    .btn.btn-link {
        outline: none;
        color: #fff;
        opacity: 0.6;
        transition: all 0.3s ease;
        padding: 0 15px;
        margin: 0;
        display: flex;
        align-items: center;

        .chevron-down {
            width: 24px;
            height: 24px;

            &.rotated {
                transform: rotate(180deg);
            }
        }

        &:hover,
        &:focus {
            outline: none;
            opacity: 1;
            text-decoration: none;
            box-shadow: none;
            color: #fff;
        }
    }
}

.card-body {
    padding: 0;
}

.mobile-config {
    .gallery-selecter-img {
        margin-bottom: 0 !important;
    }
}

.app-info-tooltip {
    cursor: pointer;
}

.modulator-details-page {
    #app-modulator {
        position: relative;

        app-about-brand {
            form {
                flex-direction: column;
            }
        }

        .mt-40 {
            width: 100%;
            flex-direction: column;
        }

        ng-wizard {
            height: 100%;
            width: 100%;
            position: relative;
        }

        #bottom-button-bar {
            position: relative;
        }

        .mockup-container {
            .has-action {
                pointer-events: none;
            }
        }
    }
}

preview-modulator {
    #app-modulator {
        position: relative;
        background: #fff;

        .nav-tabs {
            display: none;
        }

        .app-modulator-header {
            display: none !important;
        }

        app-mobile-prototype {
            margin: 0;

            img.home-screen {
                position: relative;
                top: -60px;
            }
        }

        .overlay-animation {
            display: none;
        }
    }

}

.cal-week-view {
    border-radius: 5px;

    .cal-time-events {
        .cal-event {
            width: calc(100% - 16px);
            height: 100%;
            margin: 1px 1px 1px 8px;
        }
    }

    .cal-day-headers {

        .cal-header {
            background: #EEEEEFCC;
            color: #232323;
            font-size: 14px;
            border-right-color: #D5D5D5 !important;
            padding: 8px ​5px 6px;

            b {
                font-weight: 500;
            }

            span {
                font-size: 18px;
                line-height: 26px;
                color: #232323;
                opacity: 1;
                font-weight: 700;
                display: block;
                padding: 4px ​0;
            }
        }
    }

    .cal-hour {

        mwl-calendar-week-view-hour-segment {
            &:last-of-type {
                .cal-hour-segment {
                    border-bottom: 1px solid #e1e1e1;
                }
            }
        }

    }

    .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom: 1px solid transparent;
    }

    .cal-hour-segment.cal-hour-start {
        .cal-time {
            color: #606060;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
        }

    }
}

app-onboarding-wizard {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    background: #F9F9F9;
}

app-lets-begin,
app-theme-color {
    display: flex;
    width: 100%;
    height: 100%;
}

.wizard-box {
    display: flex;
    width: 100%;
    height: 100%;
    background: #F9F9F9;
    padding: 38px 0 0;
    flex-direction: column;
    overflow: hidden;

    @media screen and (max-width: 576px) {
        padding: 20px 16px 0;
    }

    .container {
        order: 1;
        height: calc(100% - 88px);
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .bottom-wizard-box {
        width: 100%;
        background: #FCFCFC;
        padding: 16px 0;
        order: 2;
        justify-content: center;
        position: relative;

        .button-true-green {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            outline: none;
            padding: 17px 136px;
            background: #0FB87E;
            pointer-events: all;
            cursor: pointer;
            height: auto;
            margin: 0;

            @media screen and (max-width: 576px) {
                padding: 17px 100px;
                width: calc(100% - 32px);
            }



            &:focus {
                box-shadow: none;
            }

            &.disabled {
                color: #A8A8A8 !important;
                background: #EEEEEF;
                pointer-events: none;
                cursor: not-allowed;
            }

            &:hover {
                background: #0E8D61;
            }
        }

        .images-uploaded-box {
            border-radius: 5px;
            border: 1px solid #A8A8A880;
            padding: 12px 16px;
            position: absolute;
            right: 16px;
            top: 11px;
            display: flex;
            background: #FFFFFF;
            align-items: center;

            &.all-five {
                background: #E8F4F0;
            }

            .checkmark-selected {
                margin-right: 10px;
            }

            p {
                margin: 0 0 8px;
            }

            .uploaded-images-progress {
                background: #A8A8A84D;
                border-radius: 8px;
                width: 200px;
                height: 8px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    top: 0;
                    left: 0;
                    height: 8px;
                    background: #0FB87E;
                    border-radius: 8px;
                }

                &.i1-images {
                    &:after {
                        width: 20%;
                    }
                }

                &.i2-images {
                    &:after {
                        width: 40%;
                    }
                }

                &.i3-images {
                    &:after {
                        width: 60%;
                    }
                }

                &.i4-images {
                    &:after {
                        width: 80%;
                    }
                }

                &.i5-images {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    .stepper-box {
        background: #A8A8A84D;
        border-radius: 8px;
        width: 100%;
        height: 16px;
        margin-bottom: 18px;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            border-radius: 8px;
            width: 0;
            height: 16px;
            left: 0;
            background: #0FB87E;
        }

        &.second-step {
            &:after {
                width: 25%;
            }
        }

        &.third-step {
            &:after {
                width: 50%;
            }
        }

        &.fourth-step {
            &:after {
                width: 75%;
            }
        }

        &.included-app {
            &.second-step {
                &:after {
                    width: 12.5%;
                }
            }

            &.third-step {
                &:after {
                    width: 25%;
                }
            }

            &.fourth-step {
                &:after {
                    width: 37.5%;
                }
            }

            &.fifth-step {
                &:after {
                    width: 50%;
                }
            }

            &.sixth-step {
                &:after {
                    width: 62.5%;
                }
            }

            &.seventh-step {
                &:after {
                    width: 75%;
                }
            }

            &.eight-step {
                &:after {
                    width: 87.5%;
                }
            }
        }
    }

    .wizard-box-stepper-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        position: sticky;
        top: 0;
        background-color: #F9F9F9;
        z-index: 100;
        padding-bottom: 20px;

        a.back-btn {
            position: absolute;
            top: 33px;
            left: 0;
            color: #232323;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            display: flex;

            img {
                margin-right: 8px;
            }

            &.top-0 {
                top: 0;
            }
        }

        .creating-app-box {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: center;

            h1 {
                font-size: 56px;
                line-height: 64px;
                color: #232323;
                font-weight: 500;
                margin: 40px 0 12px;

                max-width: 600px;
                text-align: center;

                b {
                    font-weight: 900;
                }
            }

            p {
                font-size: 18px;
                line-height: 26px;
                color: #606060;
                font-weight: 500;
            }

            .logo {
                width: 120px;
            }

            .wizard-box-stepper-box {
                margin: 56px 0 70px;
                max-width: 560px;
            }

            h5 {
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 16px;
                max-width: 390px;
                text-align: center;
            }

            .trainers-box {
                img {
                    margin: 0 4px;
                }
            }
        }

        .progress {
            border-radius: 8px;
            background-color: #A8A8A84D;
        }

        .progress-bar {
            background-color: #0FB87E;
            border-radius: 8px;
        }

    }

    .wizard-box-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 60px;
        text-align: center;
        height: calc(100% - 60px);
        // height: calc(100% - 100px);
        // overflow-y: auto;

        @media screen and (max-width: 667px) {
            margin-top: 10px;
        }
    }

    .theme-color-content {
        display: flex;
        width: 100%;
        margin-top: 35px;
        justify-content: center;

        .colors-box {
            display: flex;
            width: 25%;
            padding: 6px;

            &.selected-color {
                .colors-box-inner {
                    background: #175CFF0D;
                    border: 1px solid #175CFF;
                }
            }

            .colors-box-inner {
                width: 100%;
                display: flex;
                border: 1px solid #A8A8A880;
                border-radius: 5px;
                padding: 12px 10px;
                background: #fff;
                position: relative;
                cursor: pointer;
                transition: all 0.2s ease;

                &:hover {
                    background: #EEEEEF;
                }
            }

            .color-indicator {
                border-radius: 5px;
                width: 44px;
                height: 44px;
            }

            .color-desc {
                display: flex;
                flex-direction: column;
                margin-left: 12px;
                align-items: flex-start;

                h6 {
                    line-height: 22px;
                }

                p {
                    margin: 0;
                }
            }

            .checkmark-selected {
                position: absolute;
                right: 8px;
                top: 8px;
                width: 30px;
                height: 30px;
                background: #0FB87E;
                border-radius: 50%;

                &:after {
                    position: absolute;
                    content: "";
                    width: 18px;
                    height: 12px;
                    background: url(../../assets/icons/check-box-2x.png) no-repeat center center;
                    top: 9px;
                    left: 6px;
                    background-size: cover;
                }
            }
        }

        .theme-color-box {
            width: 365px;
            height: 320px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            border: 1px solid #A8A8A880;
            cursor: pointer;

            &.selected-theme {
                border: 1px solid #175CFF;

                .theme-color-box-desc {
                    background: #175CFF0D;
                }
            }

            &:hover {
                .theme-color-box-desc {
                    background: #EEEEEF;
                }
            }

            &:first-of-type {
                margin-right: 12px;
            }

            &:last-of-type {
                margin-left: 12px;
            }

            .theme-color-box-color {
                width: 100%;
                background: #fff;
                height: 245px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            .theme-color-box-desc {
                background: #fff;
                display: flex;
                flex-direction: column;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                width: 100%;
                border-top: 1px solid #A8A8A880;
                padding: 18px 0 10px;
                position: relative;
                transition: all 0.2s ease;

                .checkmark-selected {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    width: 30px;
                    height: 30px;
                    background: #0FB87E;
                    border-radius: 50%;

                    &:after {
                        position: absolute;
                        content: "";
                        width: 18px;
                        height: 12px;
                        background: url(../../assets/icons/check-box-2x.png) no-repeat center center;
                        top: 9px;
                        left: 6px;
                        background-size: cover;
                    }
                }
            }

            &:last-of-type {
                .theme-color-box-color {
                    background: #232323;
                }
            }
        }
    }

    h2 {
        font-size: 32px;
        line-height: 40px;
        color: #232323;
        font-weight: bold;

        @media screen and (max-width: 576px) {
            font-size: 24px;
        }
    }

    h6 {
        font-size: 16px;
        line-height: 24px;
        color: #232323;
        font-weight: 700;
    }

    p {
        color: #606060;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;

        @media screen and (max-width: 576px) {
            font-size: 14px;
        }

        &.smaller {
            font-size: 14px;
            line-height: 22px;

            @media screen and (max-width: 576px) {
                font-size: 12px;
            }
        }
    }
}

tag-input {
    border: 1px solid #bfbfbf;
    border-radius: 5px;
    background: #fff;
    margin: 10px 0;
}

.ng2-tag-input.ng2-tag-input--focused {
    border-bottom: none !important;
}

.ng2-tag-input {
    border-bottom: none !important;
    padding: 0 !important;
    min-height: 43px !important;
    display: flex !important;
    align-items: center !important;
}

.ng2-tag-input__text-input {
    margin: 0;
    font-family: $font-family !important;
    height: 43px !important;
    padding: 10px 0px 10px 0px !important;
}

.ng2-tags-container {
    padding-left: 15px;

    tag {
        font-size: 12px !important;
        font-weight: 600 !important;
        color: #232323 !important;
        background: #A8A8A84D !important;
        border-radius: 5px !important;
        height: 24px !important;
        font-family: $font-family !important;
        align-self: center;
        align-items: center;
        line-height: 1 !important;

        &:hover {
            color: #232323 !important;
            background: #A8A8A84D !important;
            box-shadow: none !important;
        }
    }

    .tag-wrapper {
        text-transform: uppercase;
        align-items: center;
    }

    delete-icon {
        svg {
            height: 16px !important;
        }

        &:hover {
            transform: none !important;
        }
    }
}

qrcode {
    display: flex;
    border: 1px solid #6060604D;
    border-radius: 5px;

    .qrcode {
        display: flex;

        canvas {
            border-radius: 5px;
        }
    }
}

.cal-month-view {
    border-radius: 5px;

    .cal-cell-top {
        min-height: auto;
    }


    .cal-cell-row:hover {
        background-color: transparent;
    }

    .cal-day-cell {
        &.cal-out-month {
            .cal-day-number {
                opacity: 1;
            }
        }

        &.cal-weekend {
            .cal-day-number {
                color: #606060;
            }
        }

        min-height: 165px;
        width: 100%;
        overflow: hidden;

        &.cal-today {
            background-color: transparent;

            .cal-day-number {
                font-size: 12px;
                color: #fff;
                background-color: #0561FC;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                padding: 2px;
                text-align: center;
            }
        }
    }

    .cal-day-number {
        font-size: 12px;
        color: #606060;
        font-weight: 500;
        opacity: 1;
        float: left;
        margin-left: 15px;
    }

    .cal-days {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        >div {

            &:last-child {
                .cal-cell-row {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

    }

    .cal-header {
        .cal-cell {
            padding: 11px 0;
            border-right: 1px solid #D5D5D5;
            border-top: 1px solid #D5D5D5;
            background: #EEEEEFCC;
            color: #232323;
            font-size: 14px;
            font-weight: 500;

            &:first-of-type {
                border-left: 1px solid #D5D5D5;
                border-top-left-radius: 5px;
            }

            &:last-of-type {
                border-top-right-radius: 5px;
            }
        }
    }
}

.event-title {
    padding: 3px 5px;
    margin: 0 8px 5px;
    border-radius: 3px;
    outline: 1px solid $color-box-shadow;
    border-left: 4px solid transparent;

    p {
        color: #232323;
        font-weight: 600;
        font-size: 12px;
        padding: 0;
        margin: 0;
    }

    small {
        color: #606060;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        line-height: 20px;
        font-size: 12px;
    }
}

.cal-disabled {
    background-color: #eee;
    pointer-events: none;

    .event-title {
        pointer-events: all;
    }
}

.cal-day-view {
    .cal-events-container {
        margin-left: 0;
    }

    .cal-week-view .cal-time-events .cal-event-container {
        padding-left: 70px;
    }
}