@import '../abstracts/variables';

.app-info,
.billing-info,
.owner-info {
    width: 100%;
    display: flex;
    flex-direction: column;

    .form-row-input,
    .form-row {

        input,
        textarea {
            width: 100%;
            color: $color-primary;
            margin: 8px 0px 40px;

            @media only screen and (max-width: 991px) {
                margin: 8px 0px 16px !important;
            }

        }
    }

    .input-label {
        position: relative;
        display: inline-block;
        align-self: flex-start;
        padding: 6px 0 0 0;

    }

    ng-select {
        width: 100%;
        height: 45px;
        color: $color-primary;
        margin: 8px 0px 40px;

        @media only screen and (max-width: 991px) {
            margin: 8px 0px 16px !important;
        }
    }
}

.app-visuals {
    ng-dropdown-panel {
        .ng-dropdown-panel-items {
            max-height: 420px !important;
        }
    }
}


.app-info,
.app-visuals {
    popover-container {
        box-shadow: 0px 5px 30px #30303033;
        border: none;

        .popover {
            padding: 0 !important;

        }




        .arrow,
        .popover-arrow {
            display: none !important;
        }

        .popover-body,
        .popover-content {
            padding: 0 !important;
        }
    }
}

.app-visuals-dropdown {
    .ng-dropdown-panel-items {
        min-height: 430px;

        &:nth-child(2) {
            min-height: 430px;
        }
    }
}


.stepper,
.app-info,
.billing-info,
.owner-info,
.header-wrapper,
.app-visuals {
    .logo-upload-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
    }


    .long-description,
    .short-description {
        height: 110px;
        overflow-y: auto;
    }

    file-upload {
        width: 100%;
    }

    .small-img {
        max-width: 260px;
        min-height: 242px;
        flex-wrap: wrap;
        align-content: baseline;

        @media only screen and (max-width: 576px) {
            min-height: 100px;
        }

        .small-image-item {
            min-width: 80px;
            min-height: 80px;
            max-width: 80px;
            max-height: 80px;
            margin-bottom: 8px;
            margin-left: 8px;
        }


        .item-3,
        .item-6,
        .item-9 {
            margin-left: 0;

            @media only screen and (max-width: 350px) {
                margin-right: 0;
            }
        }

        .item-2,
        .item-4,
        .item-5,
        .item-6 {
            @media only screen and (max-width: 350px) {
                margin-left: 0;
                margin-right: 8px;
            }
        }

        file-upload {
            min-width: 80px;
            min-height: 80px;
            max-width: 80px;
            max-height: 80px;
        }
    }


    .disabled-button {
        background-color: gray;
        cursor: disabled;
    }



    .description {
        font-style: italic;
        font-size: 10px;
        color: gray;
        line-height: 12px;

        &-subheading {
            margin-bottom: 25px;
            max-width: 500px;
            margin-top: -10px;
        }

        &-heading {
            margin-bottom: 25px;
            max-width: 500px;
            margin-top: -25px;
        }

        &-input {
            margin-top: -35px;
        }

        &-regular {
            margin-bottom: 25px;
            max-width: 350px;
        }
    }

    .descriptor {
        font-size: 14px;
        color: #606060 !important;
        font-weight: 500;
    }

    .input-descriptor {
        margin-top: -35px !important;
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 991px) {
            margin-bottom: 16px;
            margin-top: -12px !important;

        }
    }

    .input-descriptor-fix {
        display: flex;
        justify-content: space-between;
        margin-top: -7px;
        margin-bottom: 35px;
    }

    .regular-descriptor {
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;
        margin-top: 0px !important;

        @media only screen and (max-width: 991px) {
            margin-bottom: 22px;
            margin-top: -12px !important;

        }
    }

    .textarea-descriptor {
        margin-bottom: 35px;
        margin-top: -40px !important;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 991px) {
            margin-bottom: 16px;
            margin-top: -15px !important;
        }
    }

    .textarea-descriptor-fix {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
        margin-top: 5px;
    }

    .stepper-wrapper,
    .app-info-stepper {
        box-shadow: none !important;
        background-color: transparent;
    }



    .profile-subheading {
        margin-bottom: 30px;

        span {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            margin-left: 15px;

            img {
                width: 20px;
                height: 20px;
                opacity: .8;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .logo-upload-wrapper {
        display: flex;
        width: 100%;

        gallery-item {
            min-height: 238px;
        }
    }

    .cards {
        margin-top: 30px;
        display: flex;
        font-weight: 300;
        width: 100%;
        align-items: center;
        padding-bottom: 30px;

        span {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            margin-left: 15px;

            img {
                width: 20px;
                height: 20px;
                opacity: .8;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .app-visuals {
        .gallery-selecter-img {
            margin-bottom: 0;
        }
    }

    .row-wrapper {
        width: 100%;
    }

    .locked-content {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .name,
        .package-options {
            width: 50%;

            @media (max-width: 768px) {
                width: 100%;
            }

            input {
                width: 100%;
            }
        }

        .name {
            margin-right: 15px;

            @media (max-width: 768px) {
                margin-right: 0;
            }
        }

        .package-options {
            margin-left: 15px;

            @media (max-width: 768px) {
                margin-left: 0;
            }
        }

        .logo-wrap {
            width: 120px;
        }

    }

    .add-background {
        padding: 48px 32px 30px 32px !important;
        background-color: #fff !important;
    }


}

.profile-stepper-modal {
    max-width: 100%;
    margin: 0;
    padding-left: 0;
    height: 100vh;
    top: 0 !important;

    .modal-content {
        height: 100vh;
        display: block;
        background-color: transparent;
        max-width: 100% !important;
        overflow: hidden;

        .stepper {
            width: 97%;
            max-width: 1200px;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            position: fixed;
            height: calc(100% - 20px);

            .brand-name-wrapper {
                position: fixed;
                width: 100%;
                top: 0;
            }

            .stepper-header-wrapper {
                position: fixed;
                top: 60px;
            }

            .stepper-content {
                position: fixed;
                top: 128px;
                height: calc(100vh - 213px);
                overflow-y: auto;

                @media only screen and (max-width: 768px) {
                    height: calc(100vh - 200px);
                    top: 116px;
                }

                @media only screen and (max-width: 567px) {
                    height: calc(100vh - 196px);
                    top: 108px;
                }

            }

            .stepper-pagination {
                position: fixed;
                bottom: 0;
            }
        }
    }
}


.input-label {
    position: relative;

    &-tooltip {
        position: absolute;
        display: inline-block;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        cursor: pointer;
        background-image: url('../../assets/icons/ic_tooltip.svg');
    }
}

.owner-info,
.billing-info {
    flex-direction: column;

    ng-select {
        .ng-value {
            min-width: 100px;
        }
    }
}


.stepper-bottom-margin {
    @media only screen and (max-width: 991px) {
        margin-bottom: 100px !important;
    }

}

.form-submitted {
    .file-upload {
        form:invalid {
            .gallery-body-imgs-add {
                border: 1px solid $color-error  !important;
            }
        }
    }
}
