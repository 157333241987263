// Font
$font-family: 'Montserrat',
sans-serif;
$font-size: 14px;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$line-height: 1.4;

// Colors
$color-background: #ffffff;
$color-background-gray: #F3F3F3;
$color-transparent: #ffffff00;
$color-background-primary: #0b2441;
$color-primary: #232323;
$color-blue: #3297fd;
$color-primary-transparent: rgba(11, 36, 65, 0.05);
$color-primary-transparent-1: rgba(11, 36, 65, 0.5);
$color-primary-transparent-2: rgba(11, 36, 65, 0.1);
$color-primary-transparent-3: #0C0F154D; //0.3 opacity
$color-primary-transparent-no-opacity: #f2f3f5;
$color-primary-transparent-modal: rgba(11, 36, 65, 0.7);
$color-primary-darker: #4A4A4A;
$color-primary-1: #FF1E00;
$color-primary-1-transparent: rgba(0, 176, 76, 0.05);
$color-primary-2: #FF6300;
$color-primary-1-opacity: #d31900;
$color-primary-2-opacity: #d65200;
$color-primary-text-opacity: #9d9d9d;
$color-true-white: #fff;
$color-gray: #bbbbbb;
$color-gray-button: #efefef;
$color-gray-darker: #757575;
$color-black: #0b2441;
$color-error: #b00020;
$color-success: #0F815A;
$color-warning: #ff9122;
$color-text: #bbbbbb;
$color-link-default: #bbbbbb;
$color-link-hover: #757575;
$color-input-touched: #eff7fb;
$color-hr: rgba(191, 191, 191, 0.2);
$color-table-th: #bfbfbf;
$color-heading: #ffffff;
$color-shadow: #707070;
$color-box-shadow: rgba(122, 122, 122, 0.16);
$color-primary-green: #0fb87e;
$color-border-light: #e4e4e4;

$color-input-border: #868B94;
$color-input-background: #EFF2F8;

// Margins
$margin: 5px;
$padding: 20px;

// Image path
$image-path: '';

// Retina image suffix
$image-suffix: '-2x';

// Font path
$font-path: '../fonts/';

// Grid
$grid-columns: 12;
$grid-column-gutter: 30px;

// break points
$xs: 575px;
$sm: 767px;
$md: 991px;
$lg: 1280px;
$xlg: 1680px;
