.page-list {
    background-color: $color-background-gray;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch !important;
    height: 100%;

    &-title {
        @include breakpoint(xlg) {
            font-size: 22px;
            width: 30%;
        }

        @include breakpoint(lg) {
            font-size: 18px;
        }
    }

    &-wrapper {
        padding: 56px 88px;

        @include breakpoint(xlg) {
            padding: 32px;
        }

        @include breakpoint(lg) {
            padding: 26px 12px;
        }
    }

    &-headline {
        @include flex(flex, row, nowrap, space-between, baseline);
        margin-bottom: 34px;
        z-index: 5;
    }

    &-options {
        @include flex(flex, row, nowrap, flex-end, center);

        @include breakpoint(xlg) {
            @include flex(flex, row, wrap, flex-end, center);
            width: 80%;
        }
    }

    .button-true-green {
        margin: 0 0 0 12px;

        @include breakpoint(xlg) {
            margin-right: 30px;
            font-size: 13px;
        }

        @include breakpoint(lg) {
            margin-right: 10px;
        }
    }

    table {
        border-collapse: separate;
        border-spacing: 0 7px;
        margin-top: 20px;

        @include breakpoint(xlg) {
            margin-top: 30px;
        }

        thead {
            th {
                border-bottom: none;
                border-top: none;
                color: $color-primary;
                font-size: 14px;
                font-weight: 500;
                padding: 0 16px 15px 16px;
                vertical-align: middle;

                @include breakpoint(xlg) {
                    font-size: 13px;
                    padding: 0 8px 15px 8px;
                }

                @include breakpoint(xs) {
                    font-size: 11px;
                    padding: 0px 8px 4px 8px;
                }

                &:first-child {
                    padding-left: 20px;

                    @include breakpoint(xs) {
                        padding-left: 5px;
                    }
                }
            }
        }

        tbody {
            tr {
                &:hover {
                    box-shadow: 0px 0px 13px -10px $color-shadow;
                }
            }

            td,
            th {
                border: none;
                color: $color-primary;
                background-color: $color-background;
                font-size: 14px;
                font-weight: 400;
                vertical-align: middle;
                padding: 4px 16px;

                @include breakpoint(xlg) {
                    font-size: 13px;
                    padding: 0 8px;
                }

                @include breakpoint(xs) {
                    font-size: 11px;
                    padding: 0px 4px 0px 4px;
                }

                &:first-child {
                    padding-left: 20px;

                    @include breakpoint(xs) {
                        padding-left: 5px;
                    }
                }

                &.slug {
                    font-weight: 300;
                    color: $color-table-th;
                }
            }

            .td-icon {
                position: relative;
                bottom: 6px;
                left: 7px;

                @include breakpoint(xlg) {
                    width: 14px;
                }
            }

            .button-more-info {
                right: 22px;
                width: 40px;
                height: 30px;
            }
        }
    }

    &-th {
        &-actions {
            width: 80px;
            min-width: 80px;

            &-wrapper {
                display: flex;
                text-align: left;
                flex-flow: row nowrap;
                vertical-align: text-bottom;
                width: 100%;

                span {
                    padding: 14px;
                }

            }
        }
    }

    &-td {
        &-image {
            @include background-image("/assets/img/profile-picture",
                png,
                cover,
                center,
                no-repeat);
            border-radius: 5px;
            margin: 0;
            height: 45px;
            width: 45px;
        }
    }

    &-td {
        &-user {
            width: 60px;

            .td-icon {
                top: 12px;
                bottom: 0;
            }

            .button-more-info {
                right: 3px !important;
                bottom: 12px;
            }
        }

        &-5 {
            width: 5%;

            @include breakpoint(xs) {
                text-align: center;
            }
        }

        &-10 {
            width: 10%;
        }

        &-15 {
            width: 15%;
        }

        &-20 {
            width: 20%;
        }

        &-25 {
            width: 25%;
        }

        &-30 {
            width: 30%;
        }

        &-35 {
            width: 35%;
        }

        &-40 {
            width: 40%;
        }

        &-43 {
            width: 43%;
        }

        &-45 {
            width: 45%;
        }

        &-48 {
            width: 48%;

            @include breakpoint(lg) {
                width: 46%;
            }
        }

        &-50 {
            width: 50%;

            @include breakpoint(lg) {
                width: 40%;
            }
        }

        &-56 {
            width: 56%;

            @include breakpoint(lg) {
                width: 50%;
            }
        }

        &-60 {
            width: 60%;

            @include breakpoint(lg) {
                width: 50%;
            }
        }

        &-61 {
            width: 61%;

            @include breakpoint(lg) {
                width: 58%;
            }
        }

        &-70 {
            width: 70%;

            @include breakpoint(lg) {
                width: 66%;
            }
        }

        &-73 {
            width: 73%;

            @include breakpoint(lg) {
                width: 70%;
            }
        }

        &-80 {
            width: 80%;

            @include breakpoint(lg) {
                width: 68%;
            }
        }

        &-83 {
            width: 83%;
        }

        &-85 {
            width: 85%;
        }

        &-93 {
            width: 92%;

            @include breakpoint(lg) {
                width: 90%;
            }
        }

        &-name {
            width: calc(80% - 100px);
        }

        &-actions {
            width: 80px;
            min-width: 80px;

            &-wrapper {
                display: flex;
                text-align: left;
                flex-flow: row nowrap;
                width: 100%;

                @include breakpoint(xs) {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        &-longer-text {
            width: 40%;

            p {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 300px;
            }
        }

        &-shorter-text {
            width: 27%;

            p {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 300px;
            }
        }
    }

    .mark {
        margin: 15px 6px;
        width: 18px;
        height: 18px;

        &.true,
        &.false {
            cursor: pointer;
            border: 1px solid $color-border-light;
            border-radius: 2px;
            background-color: $color-background;
            transition: background-color 0.2s linear;
        }

        &.true {
            @include flex(flex, row, nowrap, center, center);
            @include background-image("/assets/icons/check-box",
                png,
                10px auto,
                center,
                no-repeat);
            background-color: $color-primary-1;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }

    .rename-popup-wrapper {
        width: 250px;
        display: flex;
        flex-direction: column;
        overflow: hidden;


        input {
            width: 100%;
            padding-left: 3px;
            opacity: .6;
            font-size: 13px;
            margin: 0;

            ::placeholder,
            :focus {
                font-size: 13px;
                color: rgba(128, 128, 128, 0.548);
            }
        }
    }

    .badge-blog,
    .badge-program {
        @include breakpoint(xlg) {
            font-size: 13px;
            width: 90px;
        }
    }

    .filter-row {
        @include flex(flex, row, nowrap, space-between, center);

        &.hide-filters {
            display: none;
        }

        &-input {
            width: 20%;
        }
    }
}

.search-wrapper {
    background-color: $color-transparent;
    margin-right: 36px;

    ng-select {
        width: 100%;
    }

    @include breakpoint(lg) {
        margin-right: 12px;
    }

    label {
        @include background-image("/assets/icons/search",
            png,
            auto,
            center,
            no-repeat);
        height: 25px;
        top: 7px;
        right: calc(100% - 117px);
        position: relative;
        margin: 0;
        font-size: 14px;
        width: 30px;
        z-index: 1;
    }

    input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $color-table-th;
        background-color: $color-transparent;
        padding-left: 40px;
        right: -60px;
        margin: 0;
        width: 300px;
        font-weight: 400;
        position: relative;

        @include breakpoint(xlg) {
            font-size: 13px;
            width: 230px;
        }

        &:focus {
            border-bottom: 1px solid $color-primary;
            transition: border-color 0.5s linear;
            @include placeholder-color($color-primary);

            &+label {
                background-image: url(/assets/icons/search-focus.png);
                transition: background-image 0.5s linear;
            }
        }
    }
}

.overflow {
    &-table {
        @include table-overflow-fix;
    }
}