.login,
.signup {
    background-color: $color-primary;
    height: 100vh;

    &-wrapper {
        @include flex(flex, column, nowrap, center, center);
        height: 100vh;
    }

    &-logo {
        margin-bottom: 100px;
    }

    &-header {
        @include breakpoint(sm) {
            font-size: 32px;
            text-align: center;
        }
    }

    &-description {
        font-size: 14px;
        font-weight: 300;
    }

    &-form {
        @include flex(flex, column, nowrap, flex-start, flex-start);
        margin-top: 60px;
        width: 35%;

        .text-input {
            width: 100%;
        }

        @include breakpoint(md) {
            width: 45%;
        }

        @include breakpoint(sm) {
            width: 55%;
        }

        @include breakpoint(xs) {
            margin-top: 30px;
            width: 95%;
        }

        .ng-invalid.ng-touched,
        .form-submitted.ng-invalid {
            @include background-image('/assets/icons/error',
                png,
                10px 10px,
                right,
                no-repeat);
            background-position-x: 97%;
            border: 2px solid $color-error;
        }
    }

    button {
        text-transform: uppercase;
        margin-top: 30px;
        width: 100%;
    }

    &-forgot-pass {
        text-decoration: underline;
        font-size: 13px;
        margin: 5px 0;
        font-weight: 300;
    }
}
.signup {
    button{
        width: 50%;
        min-width: 280px;
        display: inline-block;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 768px) {
    .login-logo {
        margin-bottom: 40px;
    }
}
