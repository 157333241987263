// @include background-image(logo, png, 100px 100px, center, no-repeat);
@mixin background-image(
    $file,
    $type,
    $size,
    $position,
    $repeat,
    $retina: false
) {
    background-image: url($image-path+$file+'.'+$type);
    background-position: $position;
    background-repeat: $repeat;
    background-size: $size;

    @if ($retina == true) {
        @include hidpi(1.3) {
            background-image: url($image-path+$file+$image-suffix+'.'+$type);
        }
    }
}

@mixin table-overflow-fix  {
    @media (max-width: 342px) {
       overflow-x: auto;
    }
}

// @include breakpoint(sm) {}
@mixin breakpoint($value) {
    @if $value == xs {
        @media (max-width: $xs) {
            @content;
        }
    }

    @if $value == xs {
        @media (max-width: $xs) {
            @content;
        }
    }

    @if $value == sm {
        @media (max-width: $sm) {
            @content;
        }
    }

    @if $value == md {
        @media (max-width: $md) {
            @content;
        }
    }

    @if $value == lg {
        @media (max-width: $lg) {
            @content;
        }
    }

    @if $value == xlg {
        @media (max-width: $xlg) {
            @content;
        }
    }
}

// @include column(2);
@mixin column($value) {
    width: calc(100% / (#{$grid-columns} / #{$value}));
}

// @include column-offset(2);
@mixin column-offset($value) {
    margin-left: calc(100% / (#{$grid-columns} / #{$value}));
}

// @include flex(flex, row, wrap, flex-start, flex-start);
@mixin flex(
    $display,
    $flex-direction,
    $flex-wrap,
    $justify-content,
    $align-items,
    $align-content: null
) {
    @if $display == 'inline-flex' {
        display: inline-flex;
    } @else {
        display: flex;
    }
    flex-flow: $flex-direction $flex-wrap;
    justify-content: $justify-content;
    align-items: $align-items;
    align-content: $align-content;
}

// @include hidpi(1.3)
@mixin hidpi($ratio: 1.3) {
    @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
        only screen and (min--moz-device-pixel-ratio: $ratio),
        only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
        only screen and (min-resolution: round($ratio * 96dpi)),
        only screen and (min-resolution: $ratio * 1dppx) {
        @content;
    }
}

// @include link-color(#fff, #999, none, underline);
@mixin link-color($value1, $value2, $value3: false, $value4: false) {
    &:link,
    &:visited {
        color: $value1;
        text-decoration: $value3;
    }
    &:hover,
    &:active {
        color: $value2;
        text-decoration: $value4;
    }
}

// @include list-reset;
@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}

// @include momentum-scroll;
@mixin momentum-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

// @include placeholder-color(#fff);
@mixin placeholder-color($value) {
    &::-webkit-input-placeholder {
        color: $value;
    }
    &:-moz-placeholder {
        color: $value;
    }
    &::-moz-placeholder {
        color: $value;
    }
    &:-ms-input-placeholder {
        color: $value;
    }
}

// @include position-absolute($top: 10px, $left: 10px);
@mixin position-absolute($top: null, $right: null, $bottom: null, $left: null) {
    bottom: $bottom;
    left: $left;
    position: absolute;
    right: $right;
    top: $top;
}

// @include responsive-img;
@mixin responsive-img {
    display: block;
    height: auto;
    max-width: 100%;
    min-width: 100%;
}

// @include text-truncate;
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// @include vertical-align;
@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// @include reset-vertical-align;
@mixin reset-vertical-align {
    top: auto;
    transform: none;
}

@mixin absolute-center(){
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}