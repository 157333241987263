.create-page {
    background-color: $color-background-gray;
    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch !important;
    height: 100%;

    &-wrapper {
        padding: 56px 88px;

        @include breakpoint(xlg) {
            padding: 32px;
        }

        @include breakpoint(lg) {
            padding: 26px 12px;
            margin-bottom: 40px;
        }

        @include breakpoint(sm) {
            margin-bottom: 80px;
        }
    }

    &-headline {
        @include flex(flex, row, nowrap, space-between, center);
        margin: 0;
    }

    &-title {
        @include breakpoint(xlg) {
            font-size: 22px;
        }

        @include breakpoint(lg) {
            font-size: 20px;
        }
    }

    &-options {
        @include flex(flex, row, nowrap, flex-end, center);

        .button-gray {
            margin: 0 12px 0 32px;
        }

        .button-true-green {
            margin: 0 0 0 12px;
        }
    }

    &-content {
        margin-top: 64px;

        @include breakpoint(lg) {
            margin-top: 26px;
        }

        form {
            width: 100%;
            @include flex(flex, row, nowrap, flex-start, flex-start);

            @include breakpoint(md) {
                @include flex(flex, column, nowrap, flex-start, flex-start);
            }
        }

        &-full {
            width: 100%;
            box-shadow: 0px 0px 35px -30px $color-shadow;
            padding: 48px 32px 30px 32px;
            background-color: #fff;
            display: flex;
            flex-direction: column;

            .row-full-width {
                display: flex;
                width: 100%;

                @media (max-width: 991px) {
                    flex-direction: column;
                }

                .form-row {
                    margin-right: 15px !important;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: calc(100% - 15px);

                    ng-select {
                        width: 100%;
                    }

                }

                .form-row:last-child {
                    margin-right: 0;
                }
            }
        }

        &-left {
            background-color: $color-background;
            box-shadow: 0px 0px 35px -30px $color-shadow;
            margin-right: 32px;
            padding: 48px 32px 8px 32px;
            width: 70%;

            @include breakpoint(lg) {
                margin-right: 12px;
                padding: 32px 32px 8px 32px;
            }

            &.set {
                padding: 8px 0;
            }

            .input-label {
                padding: 0;
                margin: 0;
                display: block;
                color: $color-primary;
                font-size: 12px;
                font-weight: 500;
            }

            .input-hint {
                padding: 0;
                margin: 0;
                margin-bottom: 38px;
                color: $color-primary;
                font-size: 12px;
                font-weight: 400;
            }

            .input,
            .bs-timepicker-field.form-control {
                margin: 8px 0 40px;
                font-weight: 400;
                font-size: 14px;
                color: $color-primary;
                width: 100%;

                @include breakpoint(lg) {
                    margin: 8px 0 16px;
                }

                &.short {
                    width: 70%;
                }

                &.extra-short {
                    width: 20%;
                }

                &.with-hint {
                    margin-bottom: 4px;
                }
            }

            .editor {
                color: $color-primary;
                display: block;
                font-size: 14px;
                margin: 8px 0 40px;
                font-weight: 400;
                width: 100%;
            }

            .input-textarea {
                border: 1px solid #bfbfbf;
                height: 150px;
                padding: 10px 30px 10px 15px;

                &.resize {
                    resize: auto;
                    min-width: 100%;
                    max-width: 100%;
                }
            }
        }

        &-right {
            background-color: $color-background;
            box-shadow: 0px 0px 35px -30px $color-shadow;
            padding: 48px 32px 8px 32px;
            width: 30%;

            @include breakpoint(lg) {
                padding: 32px 32px 8px 32px;
            }

            .input-label {
                padding: 0;
                margin: 0;
                color: $color-primary;
                font-size: 12px;
                font-weight: 500;
            }

            input {
                .input-label {
                    padding: 0;
                    margin: 0;
                    color: $color-primary;
                    font-size: 14px;
                    font-weight: 500;

                    &-disabled {
                        cursor: not-allowed;
                        color: $color-gray;
                    }
                }

                &.picture {
                    width: 100%;

                    &:disabled {
                        cursor: not-allowed;
                        border-color: $color-gray;
                    }
                }
            }
        }
    }

    .button-true-green,
    .button-gray {
        @include breakpoint(lg) {
            min-width: 100px;
        }
    }

    .button-gray {
        margin: 0 0px 0 32px;
    }
}

.input-checkbox {

    &.true,
    &.false {
        cursor: pointer;
        min-width: 18px;
        min-height: 18px;
        border: 1px solid $color-primary-1;
        border-radius: 2px;
        transition: background-color 0.2s linear;
    }

    &.true {
        @include flex(flex, row, nowrap, center, center);
        @include background-image("/assets/icons/check-box",
            png,
            10px auto,
            center,
            no-repeat);
        background-color: $color-primary-1;
        transition: background-color 0.2s linear;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

.input-textarea {
    border: 1px solid #bfbfbf;
    height: 150px;
    padding: 10px 30px 10px 15px;

    &.resize {
        resize: auto;
        min-width: 100%;
        max-width: 100%;
    }
}

.checkbox-label {
    color: $color-primary;
    font-size: 14px;
    font-weight: 300;
}

.bs-timepicker-field.form-control {
    height: 45px;
    margin: 0 !important;
}

.timepicker-box {
    margin-top: -17px;

    .btn-link {
        color: #232323;
    }
}



.form-row,
.row-full-width {
    @include flex(flex, row, wrap, space-between, center);
    margin: 0;
    width: 100%;

    &-input {
        margin-right: 15px;
        width: calc(50% - 15px);

        &.center {
            @include flex(flex, row, nowrap, flex-start, center);
            margin: 24px 0 40px;
        }

        &:last-child {
            margin-right: 0;
        }

        input {
            &:disabled {
                cursor: not-allowed;
            }
        }

        &.full-width {
            width: 100%;
        }
    }

    &-checkbox {
        @include flex(flex, row, wrap, space-between, center);
        margin-bottom: 30px;
        width: 100%;

        .form-row-input {
            margin-right: 15px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            width: calc(50% - 15px);

            input {
                &:disabled {
                    cursor: not-allowed;
                }
            }
        }
    }

    &-input-double {
        @include flex(flex, row, wrap, space-between, center);
        margin-left: 15px;
        width: calc(50% - 15px);

        &:last-child {
            margin-right: 0;
        }

        .width-60 {
            width: 60%;
        }

        .width-30 {
            width: 30%;
        }
    }

    .column {
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
}

.create-page-content-full {
    @media (max-width: 1280px) {
        padding: 22px 22px 8px;
    }

    .row-full-width {
        flex-flow: row nowrap;

        @media (max-width: 1280px) {
            flex-flow: row nowrap;
        }

        @media (max-width: 991px) {
            flex-flow: row nowrap;
        }

        .form-row {

            @media (max-width: 1280px) {
                margin-right: 0;
            }

            input {
                margin: 8px 0 40px;

                @media (max-width: 1280px) {
                    margin: 8px 0 16px;
                }
            }
        }
    }
}

.modal-body {
    .input-checkbox {

        &.true,
        &.false {
            cursor: pointer;
            border: 1px solid $color-primary-1;
            border-radius: 2px;
            transition: background-color 0.2s linear;
        }

        &.true {
            @include flex(flex, row, nowrap, center, center);
            @include background-image("/assets/icons/check-box",
                png,
                10px auto,
                center,
                no-repeat);
            background-color: $color-primary-1;
            transition: background-color 0.2s linear;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}

.required {
    &:after {
        content: "*";
        display: inline-block;
        margin-left: 5px;
        color: rgb(165, 0, 0);
    }
}