*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    // -ms-text-size-adjust: 100%;// -webkit-text-size-adjust: 100%;
    -webkit-text-size-adjust: none;
    touch-action: manipulation;
    height: 100%;
}

body {
    margin: 0;
    position: relative;
    height: 100%;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main {
    display: block;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;

    &:hover,
    &:active {
        outline-width: 0;
    }
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

strong {
    font-weight: inherit;
}

b,
strong {
    font-weight: bolder;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

dfn {
    font-style: italic;
}

mark {
    background-color: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

audio,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

img {
    border-style: none;
    display: inline-block;
    height: auto;
    max-width: 100%;
}

svg:not(:root) {
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 300;
    line-height: 1.15;
    margin: 0;

    &:focus {
        outline: none;
    }
}

button,
input {
    overflow: visible;
    -webkit-appearance: none;
    height: 45px;
    border: none;
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px 10px 10px 10px;
    @include placeholder-color($color-gray);
}

input,
.bs-timepicker-field.form-control {
    border: 1px solid $color-table-th;
    padding: 10px 30px 10px 15px;
}

input::placeholder {
    font-weight: 400;
    color: hsla(0, 0%, 38%, 0.7);
}
input:disabled {
    background: #EEEEEF;
}

// input:focus::placeholder {
//     color: $color-primary;
// }

form {

    .ng-invalid.ng-touched,
    .form-submitted.ng-invalid {

        &.input,
        &.bs-timepicker-field.form-control,
        &.editor,
        &.counter .counter-wrapper,
        &.input-textarea,
        .ngx-dropdown-button {
            border: 1px solid $color-error !important;
        }
    }

    .ng-invalid.ng-touched,
    .form-submitted.ng-invalid {

        &.counter .counter-wrapper .counter-minus,
        &.counter .counter-wrapper .counter-button,
        &.counter .counter-wrapper .counter-plus {
            border: none;
        }
    }

    app-pause-item counter .counter-wrapper,
    .counter-input-wrapper counter .counter-wrapper {
        margin: 0 !important;
    }

    app-exercise-item counter .counter-wrapper {
        width: 140px;
    }
}

gallery-item {
    &.form-submitted.ng-invalid {
        .gallery-button {
            border: 1px solid $color-error;
        }
    }

    &.disabled {
        .gallery {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}

button {
    text-transform: initial;
    font-size: 14px;
    font-family: $font-family;
    font-weight: 400;
    background-color: $color-transparent;
}

select {
    text-transform: none;
    width: 100%;
    height: 32px;
    color: $color-gray;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: $color-background;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 3px;
    border: none;
    border-radius: 5px;
    margin: 5px 0;
    padding: 2px 2px 2px 10px;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: none;
}

fieldset {}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

textarea {
    overflow: auto;
    resize: none;
    border: none;
    border-radius: 6px;
    padding: 10px;
    color: $color-gray;
    @include placeholder-color($color-gray);
}

[type='checkbox'],
[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

details,
menu {
    display: block;
}

summary {
    display: list-item;
}

canvas {
    display: inline-block;
}

template,
[hidden] {
    display: none;
}

.input-label {
    padding: 0;
    margin: 0;
    color: $color-primary;
    font-size: 14px;
    font-weight: 500;
}

.counter-button.disabled {
    .counter-wrapper {

        button,
        input {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}

#blog-form {
    figcaption.ck-editor__editable {
        min-height: 100% !important;
    }

    h1,
    h2 {
        color: $color-primary;
    }
}

counter {
    &.disabled {
        .counter-wrapper {

            button,
            input {
                cursor: not-allowed;
                opacity: 0.6;
            }
        }
    }
}

 form app-exercise-item .counter-input-wrapper counter .counter-wrapper {
    margin: 0 auto !important;
}

app-exercise-item,
app-pause-item {
    counter {
        &.disabled {
            .counter-wrapper {

                button,
                input {
                    cursor: not-allowed;
                    opacity: 0.6;
                }
            }
        }

        @include breakpoint(lg) {
            @include flex(flex, row, nowrap, center, center);
        }

        .counter-wrapper {

            .counter-minus,
            .counter-plus {
                @include breakpoint(lg) {
                    background-size: 6px auto;
                    height: 32px;
                    width: 20px;
                }
            }

            .input {
                font-size: 14px;
                height: auto;
                width: 54px;
            }
        }
    }
}

.assign-template-popover .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-left: 0;
    padding-right: 0;

    input {
        font-size: 13px;
        height: 100%;
        padding: 0;
    }
}

modal-calendar-event,
scheduler {
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
        width: 100%;
    }
}

modal-calendar-event {

    .ng-select.select-input.ng-select-single .ng-select-container .ng-value-container .ng-input,
    .ng-select.select-input .ng-select-container .ng-value-container .ng-input {
        width: 50% !important;
    }
}

.assign-template-popover {
    .ng-select-container {
        min-width: 248px;
    }

    .ng-dropdown-panel-items {
        .ng-option {
            padding: 8px 12px !important;
        }
    }
}

.ck-content {
    blockquote {
        overflow: auto !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        font-style: normal !important;
        border-left: none !important;
    }

    blockquote p:after {
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNC4xOTMiIGhlaWdodD0iMjAuMjg3IiB2aWV3Qm94PSIwIDAgMjQuMTkzIDIwLjI4NyI+CiAgICA8ZyBpZD0ibm91bl9RdW90ZV85MzA0NDJfMWExYTFhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTU3LjIwNyAtNDYxLjgxNSkiPgogICAgICAgIDxwYXRoIGlkPSJQYXRoXzE5MyIgZGF0YS1uYW1lPSJQYXRoIDE5MyIgZD0iTTE2Ny4wOTUsNDYxLjgxNWE5LjMzOSw5LjMzOSwwLDAsMC03LjI1NywyLjg2MXEtMi42MzUsMi44NzMtMi42MzEsOC45NzJWNDgyLjFoMTAuMTY5di05LjEzMWgtNC45MDhhOS4yLDkuMiwwLDAsMSwxLjA4MS00Ljk4NywzLjkyNSwzLjkyNSwwLDAsMSwzLjU0Ni0xLjYzNlptMTMuOTQ1LDBhMTEuNCwxMS40LDAsMCwwLTQuMTIyLjY5Miw3Ljc0NCw3Ljc0NCwwLDAsMC0zLjA5MiwyLjE0OCw5LjkzOSw5LjkzOSwwLDAsMC0xLjk1MywzLjcxMSwxOC40NzgsMTguNDc4LDAsMCwwLS42NzcsNS4yODNWNDgyLjFoMTAuMnYtOS4xMzFoLTQuOWE5LjIxLDkuMjEsMCwwLDEsMS4wNzQtNC45ODcsMy44NDUsMy44NDUsMCwwLDEsMy40NjYtMS42MzZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIiBmaWxsPSIjMDAwMDAwIiAvPgogICAgPC9nPgo8L3N2Zz4=');
        background-size: 30px 30px;
        display: block;
        float: right;
        height: 30px;
        position: relative;
        margin-top: 10px;
        right: 10px;
        width: 30px;
    }

    blockquote p:before {
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNC4xOTMiIGhlaWdodD0iMjAuMjg3IiB2aWV3Qm94PSIwIDAgMjQuMTkzIDIwLjI4NyI+CiAgPGcgaWQ9Im5vdW5fUXVvdGVfOTMwNDQyXzFhMWExYSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTgxLjQgNDgyLjEwMikgcm90YXRlKDE4MCkiPgogICAgPHBhdGggaWQ9IlBhdGhfMTkzIiBkYXRhLW5hbWU9IlBhdGggMTkzIiBkPSJNMTY3LjA5NSw0NjEuODE1YTkuMzM5LDkuMzM5LDAsMCwwLTcuMjU3LDIuODYxcS0yLjYzNSwyLjg3My0yLjYzMSw4Ljk3MlY0ODIuMWgxMC4xNjl2LTkuMTMxaC00LjkwOGE5LjIsOS4yLDAsMCwxLDEuMDgxLTQuOTg3LDMuOTI1LDMuOTI1LDAsMCwxLDMuNTQ2LTEuNjM2Wm0xMy45NDUsMGExMS40LDExLjQsMCwwLDAtNC4xMjIuNjkyLDcuNzQ0LDcuNzQ0LDAsMCwwLTMuMDkyLDIuMTQ4LDkuOTM5LDkuOTM5LDAsMCwwLTEuOTUzLDMuNzExLDE4LjQ3OCwxOC40NzgsMCwwLDAtLjY3Nyw1LjI4M1Y0ODIuMWgxMC4ydi05LjEzMWgtNC45YTkuMjEsOS4yMSwwLDAsMSwxLjA3NC00Ljk4NywzLjg0NSwzLjg0NSwwLDAsMSwzLjQ2Ni0xLjYzNloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIGZpbGw9IiMwMDAwMDAiLz4KICA8L2c+Cjwvc3ZnPgo=');
        background-size: 30px 30px;
        margin-bottom: 10px;
        display: block;
        height: 30px;
        width: 30px;
    }

    blockquote p {
        color: #0b2441;
        font-weight: 500;
    }
}

::-webkit-scrollbar {
    width: 0.65em;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(191, 191, 191, 0.36);
    border-radius: 25px;
}

::-webkit-scrollbar-thumb {
    background-color: #a9a9a94f;
    border-radius: 25px;
    outline: 1px solid #70809010;
}
