.recording-player-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        border: none;
    }

    .replayer-wrapper {
        position: relative;
        border: 1px solid #232323;

        canvas {
            max-width: 100%;
            max-height: 100%;
        }

        iframe {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
