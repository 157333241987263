.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.justify-start {
    justify-content: flex-start;
}


.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}

.responsive-img {
    @include responsive-img();
}

.mobile-hide {
    display: block;

    @include breakpoint(xs) {
        display: none !important;
    }
}

.alternative-exercise-input {

    .ng-value-label,
    .ng-option-label {
        white-space: normal;
    }
}



.document {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: rgb(225, 225, 225);
    padding: 5px;
    align-items: center;
    color: #000;
    border-radius: 3px;

    .button-delete {
        height: auto;
        color: #000;
        opacity: 1;
    }
}

.mobile-show {
    display: none;

    @include breakpoint(xs) {
        display: block;
    }
}

.page-wrapper {
    overflow-x: hidden;
    width: 100%;
}

.cancelation {

    .row-full-width {
        background-color: #fff;
        padding: 16px;
        padding-bottom: 100px;


    }

    #message-form {
        margin-top: 0 !important;
        border: none !important;
    }


    .column {
        width: 100%;
    }
}


.manage-subscription {

    &-modal {
        margin: 0 auto;
    }

    .popover {
        max-width: 400px !important;
        padding: 0;
    }
}


.popover {
    .max-height {
        overflow-y: auto;
        max-height: 400px;
    }

    .green-text {
        margin-top: 0;
        color: $color-primary-1;
        font-size: 15px;
        font-weight: 400;
    }

    .gray-text {
        font-size: 14px;
        color: $color-gray;
        font-weight: 300;
    }

    .blue-text {
        font-size: 14px;
        color: $color-primary;
        font-weight: 300;
    }

    &.scheduler-popover {
        min-width: 265px;
        max-width: 265px;
        border-radius: 5px 0px 5px 5px;
        box-shadow: 0px 5px 30px #21212126;
        border: none;
        margin: 0;

        .popover-arrow.arrow {
            display: none;
        }

        .close-popover {
            background: url('../../assets/icons/clear.png') no-repeat center center;
            width: 24px;
            height: 24px;
            display: block;
        }
    }
}

.details {
    .select-input {
        .ng-value-container {
            .ng-value {
                width: 100%;
            }
        }
    }
}

.sticky {
    background-color: $color-background-gray;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10000;
    padding: 8px 0;
    padding-right: 10px;
    width: calc(100% + 12px);
}

.fake-input,
.fake-ng-select,
.fake-ng-dropdown {
    background-color: #f3f3f3d5;
    border-radius: 4px;
    border: 1px solid lightgrey;
    min-height: 45px;
    display: flex;
    margin: 8px 0px 40px;
    padding: 13px 10px 13px 10px;
    position: relative;
    cursor: not-allowed;
    overflow: hidden;
    flex-wrap: wrap;
}

.fake-ng-select,
.fake-ng-dropdown {
    padding: 0px 45px 4px 0px;
    display: flex;
    flex-wrap: wrap;

    .fake-arrow {
        position: absolute;
        width: 0px;
        height: 0px;
        border: 6px solid gray;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        display: flex;
        justify-content: center;
        background-color: transparent;
        align-items: center;
        right: 15px;
        top: 50%;
        transform: translateY(-30%);

        &:after {
            content: '';
            position: absolute;
            width: 45px;
            height: 120px;
            background-color: lightgrey;
            min-width: 45px;
            z-index: 0;
            background-color: lightgray;
            opacity: 0.3;
            top: -55px;
        }
    }

}

.fake-ng-dropdown {
    align-items: center;
    padding-left: 8px;
}

.form-row,
.fom-row-input {
    .fake-item {
        padding: 4px 8px;
        margin: 5px 8px;
        background-color: rgba(11, 36, 65, 0.05);
        border-radius: 4px;
        display: inline-block;
        color: #232323;

        &:not(:last-child) {
            &::after {
                content: ',';
                display: inline-block;
                position: absolute;
                font-size: 18px;
                margin-left: 10px;
            }
        }
    }
}

.set {
    table {
        tr {
            td {
                .counter-wrapper {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.fake-checkbox {
    height: 45px;
    display: inline-flex;
    padding-left: 25px;
    position: relative;
    margin-bottom: 20px;
    align-items: center;
    cursor: not-allowed;

    &::before {
        content: '';
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #FF1E00;
        border-radius: 3px;
        margin-left: -20px;
    }
}

.fake-checked {
    &::before {
        content: '';
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 3px;
        margin-left: -20px;
        background-image: url('../../assets/icons/check-box.png');
        background-color: #FF1E00;
        background-repeat: no-repeat;
        background-size: 13px;
        background-position: 54% 56%;
    }
}

.vertical-actions-sm {
    @include breakpoint(xs) {
        display: flex;
        flex-flow: column nowrap;
    }
}

.modal-assign-custom {
    width: 100% !important;
    max-width: 800px;
    min-width: 700px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.modal-app-ready {
    max-width: 965px;
    top: calc(50% - 330px) !important;

    .modal-content {
        // background-color: transparent !important;
    }
}

.add-new-note-modal {
    .modal-content {
        width: 95% !important;
    }
}

.contact-dropdown .ng-dropdown-panel-items {
    max-height: 350px;
}

.ng-select.select-input.contact-dropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 5px 10px !important;
    border-left: none;
    display: flex;
    align-items: center;
}


questionnaire-answer-manager {
    width: 100%;
}


.modal-dialog {
    width: 100% !important;

    .modal-content {
        max-width: 1200px !important;
    }
}

.mb {
    &-20 {
        margin-bottom: 20px;
    }

    &-35 {
        margin-bottom: 35px !important;
    }
}

.custom-ng {
    margin: 10px 0px 20px !important;

    .ng-select.select-input {

        @media (max-width: 1280px) {
            margin: 10px 0px 10px !important;
        }

    }

    .ng-value {
        min-width: 125px;
    }

}

resolution-center {
    width: 100%;
}


.import-library {
    max-width: 900px;
    width: 100%;

}

.video-js-wrapper {
    position: relative;
    min-height: 120px;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 5px;
    height: auto;

    .video-js {
        min-height: 120px;

        .vjs-big-play-button {
            max-width: 55px;
        }
    }

    .button-delete {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10000;
        background-image: url('/../../assets/icons/ic_delete_circle.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30px auto;

        &:hover {
            background-image: url('/../../assets/icons/ic_delete_circle_hover.svg');
        }
    }
}

video-js {
    width: 100%;
    height: 100%;

    div {
        width: 100%;

        video {
            width: 100%;
            height: 100%;
        }
    }
}

dp-date-picker {

    * {
        border-color: #bfbfbf !important;
        border-radius: 4px;
    }

    input {
        max-width: 100%;
        cursor: pointer !important;
    }

    .dp-calendar-wrapper {

        button,
        span {
            min-width: 40px;
        }
    }


}

.full-chart {
    graph-chart {
        .chart-box {
            .chart {

                #canvas,
                canvas {
                    min-height: 500px !important;

                    @media (max-width: 768px) {
                        min-height: 350px !important;
                    }
                }
            }
        }
    }
}

.spinner {
    position: absolute;
    width: 150px;
    height: 150px;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:after {
        content: '';
        animation: infiniteRotate 0.7s linear infinite;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 3px solid transparent;
        border-left: 3px solid $color-primary-1;
        border-right: 3px solid $color-primary-1;
        position: absolute;

    }
}

@keyframes infiniteRotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.input-placeholder {
    width: 100%;
    height: 45px;
    padding-top: 5px;
    color: gray;
}

.badge {

    &-complete,
    &-not-complete {
        padding: 5px 10px;
        color: #fff;
        font-size: 18px;
        display: inline-block;
        margin-left: 15px;
    }

    .headline {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &-complete {
        background-color: $color-success;
    }

    &-not-complete {
        background-color: $color-error;
    }
}

.popover {
    z-index: 3000;
}

.noth {
    position: absolute;
    right: 45px;
    z-index: 5;

    &-option {

        &-1 {
            background-color: $color-primary-1;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            line-height: 1;
            color: #fff !important;
            display: flex;
            justify-content: center;
            font-weight: 600 !important;
            font-size: 11px !important;
            padding-top: 6px;
        }

        &-2 {
            margin-left: 5px;
            letter-spacing: 3px;
            font-weight: 600;
        }
    }
}

.new-message {
    .ng-select.select-input.contact-dropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
        padding: 25px 10px !important;
        justify-content: center;
    }
}

.business-intelligence {
    ng-select {
        .pagination {
            margin-bottom: 0px;
        }
    }
}

.chat-modal {
    top: calc(50% - 380px) !important;
    max-width: 800px;

    .modal-content {
        width: 97%;
    }
}

.fields-modal {
    width: 97% !important;
    max-width: 900px;
    max-height: 700px;
    overflow-y: auto;
    top: calc(34% - 250px) !important;
}

.app-visuals-dropdown {
    ng-dropdown-panel {
        .ng-option {
            padding: 7px 5px !important;
        }
    }
}

.back-arrow-text {
    @media screen and (max-width: 576px) {
        display: none;
    }
}


.question-tabs {
    display: inline-flex;
    width: auto;
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    margin-bottom: 40px;
    cursor: pointer;

    .tab {
        padding: 10px 8px;
        border-radius: 5px;
        border: 1px solid rgb(188, 188, 188);
        width: 50%;
        text-align: center;


        &.active {
            border-color: #000;
            font-weight: 600;
        }

        &:first-child {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        &:nth-child(2) {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
}

.total-items {
    @include flex(flex, row, wrap, flex-start, flex-start);
    margin-bottom: 32px;

    &-label {
        text-transform: uppercase;
        padding-right: 5px;
    }

    &-number {
        color: $color-primary;
        font-weight: 600;
    }
}
